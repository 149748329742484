import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'masarat-insurance-fe';
  hideHeader = false;
  hideFooter = false;
  

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private dateAdapter: DateAdapter<Date> // Inject DateAdapter
  ) {  
    this.dateAdapter.setLocale('ar');
    // Subscribe to route changes to update the header visibility
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.hideHeader = this.activatedRoute.firstChild?.snapshot.data['hideHeader'] ?? false;
      this.hideFooter = this.activatedRoute.firstChild?.snapshot.data['hideFooter'] ?? false;
    });
  }

  ngOnInit(): void{
    // Initially check the visibility based on the current route data
    this.hideHeader = this.activatedRoute.firstChild?.snapshot.data['hideHeader'] ?? false;
    this.hideFooter = this.activatedRoute.firstChild?.snapshot.data['hideFooter'] ?? false;
  }

  
}
