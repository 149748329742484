import { Component, OnDestroy, OnInit } from '@angular/core';
import { StepTabChildInterface } from '../shared/models/step-tab-child';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentService } from '../payment/services/payment-service';
import { ActivatedRoute, Params } from '@angular/router';
import { CommonService } from '../shared/services/common.service';
import { KeycloakService } from 'keycloak-angular';
import { Transaction } from '../payment/models/transaction';
import { PaymentStatus } from '../payment/models/payment-status';
import { OrderService } from '../payment/services/order-service';
import { OrderSummary } from '../payment/models/order-summary';

@Component({
  selector: 'app-insurance-policy',
  templateUrl: './insurance-policy.component.html',
  styleUrls: ['./insurance-policy.component.scss']
})
export class InsurancePolicyComponent implements StepTabChildInterface, OnInit, OnDestroy {
  
  checkoutId!: string;
  paymentStatus: PaymentStatus = {
    status: 'PENDING'
  };
  transaction!: Transaction;
  orderSummary!: OrderSummary;
  showInvoiceDetails!:boolean;

  // Stats data
  smeCrName!: string;
  smeCrNumber!: number;
  smeCrEntityNumber!: number;  newPolicyStartDate!: string;
  totalMemberCount!: number;
  sponsorsMemberCount!: number;
  dependentsMemberCount!: number;
  insClassCount!: number;
  insClassData!: any;
  quotationId!: number;
  insCompanyName!: string;
  insCompanyCode!: string;
  quotationPriceBeforeVat!: number;
  quotationVatAmount!: number;
  quotationPrice!: number;
  transactionId!: number;
  transactionPaymentId!: number;
  quotationInsCompanyCode!: string;

  constructor(
    private route: ActivatedRoute, 
    private spinnerService: NgxSpinnerService,
    private paymentService: PaymentService,
    private commonService: CommonService,
    private readonly keycloak: KeycloakService, 
    private orderService: OrderService
  ) {}

  
  ngOnInit(): void {
    
    this.showInvoiceDetails=false;

    this.keycloak.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        this.spinnerService.show();

        this.route.params.subscribe(params => {
          // Subscribe to route parameter changes
          this.route.queryParams.subscribe((params: Params) => {
            this.checkoutId = params['id'];
            this.getPaymentStatus();
            this.getTransactionData();
          });
        });
      } else {
        // User is not logged in or login failed
        this.commonService.login();
      }
    });
    
  }
  

  //3. Get the payment status
  getPaymentStatus(): void{
    
    this.paymentService.getPaymentStatus(this.checkoutId).subscribe({
      next: (data) => {
        this.paymentStatus = data;
        console.log("paymentStatus ==>", this.paymentStatus);
        //this.spinnerService.hide();
      }
    })
  }

  getTransactionData(): void{
    this.paymentService.getTransactionByCheckoutId(this.checkoutId).subscribe({
      next: (data) => {
        this.transaction = data;
        //this.paymentStatus = this.transaction.status;
        this.getOrderSummary();
       
      }
    })
  }

  getOrderSummary(): void{
    this.orderService.getOrderSummary({'orderId': this.transaction.orderId}).subscribe({
      next: (data) => {
        this.orderSummary = data;
        this.initData();
      }
    })
  }

  // Function to get the count of insClasses
  initData(): void {
    if(this.orderSummary != undefined){
      this.smeCrName = this.orderSummary.smeUserSummary.smeCrName;
      this.smeCrNumber = this.orderSummary.smeUserSummary.smeCrNumber;
      this.smeCrEntityNumber = this.orderSummary.smeUserSummary.smeCrEntityNumber;      this.newPolicyStartDate = this.orderSummary.smeUserSummary.newPolicyStartDate;
      this.totalMemberCount = (+this.orderSummary.smeUserSummary.sponsorsMemberCount) +  (+this.orderSummary.smeUserSummary.dependentsMemberCount);
      this.sponsorsMemberCount = this.orderSummary.smeUserSummary.sponsorsMemberCount;
      this.dependentsMemberCount = this.orderSummary.smeUserSummary.dependentsMemberCount;
      this.insClassCount = Object.keys(this.orderSummary.smeUserSummary.insClassCount || {}).length;
      this.insClassData = this.orderSummary.smeUserSummary.insClassCount;
      this.quotationId = this.orderSummary.quotation.id;
      this.insCompanyName = this.orderSummary.quotation.insCompany.name;
      this.insCompanyCode = this.orderSummary.quotation.insCompany.code;
      this.quotationPriceBeforeVat = this.orderSummary.quotation.priceBeforeVat;
      this.quotationVatAmount = this.orderSummary.quotation.vatAmount;
      this.quotationPrice = this.orderSummary.quotation.price;
      this.transactionId = this.transaction.id;
      this.transactionPaymentId = this.transaction.paymentId;
      this.quotationInsCompanyCode = this.orderSummary.quotation.insCompany.code;

      // For simulation purpose
      //this.paymentStatus = {status:"FAILED"};
      this.showInvoiceDetails = true;
      this.spinnerService.hide();

      
    }
  }

  saveAllOnNextAction(): void {
    //save quotation data if needs
  }

  ngOnDestroy(): void {
    
  }

}
