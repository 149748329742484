import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SMEComponent } from './sme/components/sme.component';
import { QuotationComponent } from './quotation/components/quotation.component';
import { AppComponent } from './app.component';
import { SmeDataComponent } from './sme/sme-data/sme-data.component';
import { MemberComponent } from './member/components/member.component';
import { MemberDataComponent } from './member/components/member-data/member-data.component';
import { MemberDisclosureComponent } from './member/components/member-disclosure/member-disclosure.component';
import { AuthComponent } from './auth/auth.component';
import { StepTabComponent } from './shared/components/step-tab/step-tab.component';
import { UserBoardComponent } from './user-board/user-board.component';
import { QuotationGroupsComponent } from './user-board/components/quotation-groups/quotation-groups.component';
import { PaymentComponent } from './payment/payment.component';
import { InsurancePolicyComponent } from './insurance-policy/insurance-policy.component';
import { OrderComponent } from './payment/components/order/order.component';
import { UserSettingsComponent } from './user-board/components/settings/user-settings/user-settings.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: SMEComponent },
  { path: 'payment', component: UserBoardComponent },
  { path: '1', component: StepTabComponent, data: { /*hideFooter: false,*/ stepId: 1 } }, // SmeDataComponent
  { path: '2', component: StepTabComponent, data: { /*hideFooter: false,*/ stepId: 2 } }, // QuotationComponent
  { path: '2/:qgId', component: StepTabComponent, data: { /*hideFooter: false,*/ stepId: 2 } }, // QuotationComponent
  { path: '3/:qId', component: StepTabComponent, data: { /*hideFooter: false,*/ stepId: 3 } }, // OrderComponent
  { path: '4/:oId', component: StepTabComponent, data: { /*hideFooter: false,*/ stepId: 4 } }, // PaymentComponent
  { path: 'ins-policy', component: InsurancePolicyComponent, data: { /*hideFooter: false,*/ stepId: 5 } }, // InsurancePolicyComponent

  { path: 'user-board', component: UserBoardComponent,
    children: [
      { path: 'quotation-groups', component: QuotationGroupsComponent },
      { path: 'ins-policies', component: SMEComponent },
      { path: 'settings/infos', component: UserSettingsComponent, data: { tab: 'infos' } },
      { path: 'settings/password', component: UserSettingsComponent, data: { tab: 'password' } },
      { path: 'settings/phone', component: UserSettingsComponent, data: { tab: 'phone' } },
      { path: 'settings/email', component: UserSettingsComponent, data: { tab: 'email' } },
      
      
    ]
  },

  { path: 'auth', component: AuthComponent, data: { hideHeader: true } },
  { path: 'assets/keycloak-silent-check-sso.html', component: AppComponent }

  //{ path: 'member', component: MemberComponent },
  //{ path: 'sme/members', component: MemberDataComponent },
  //{ path: 'sme/disclosures', component: MemberDisclosureComponent },
  //{ path: 'quotation', component: QuotationComponent },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
