import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Member } from '../models/member';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Disclosure } from '../models/disclosure';
import { MemberDisclosure } from '../models/member-disclosure';
import { AppConfigService } from 'src/app/shared/config/app-config.service';


//const baseUrl = 'http://localhost:8080/api/sme/1/member';

@Injectable({
  providedIn: 'root'
})

export class DisclosureService implements OnInit, OnDestroy {
  
    disclosure: Disclosure[] = [];
    private apiEndpoints: any;

    constructor(private http: HttpClient, private configService: AppConfigService) { 
      this.apiEndpoints = configService.getApiEndpoints();
    }

    ngOnInit(){
      
    }

    getDisclosureList(): Observable<any> {
      const endpoint = this.apiEndpoints.disclosure.getDisclosures; 
      const url = this.configService.getEndpointUrl(endpoint);

      return this.http.get(url);
  }

 addMemberDisclosures(memberId: number, data: any): Observable<any> {
    const endpoint = this.apiEndpoints.member.addMemberDisclosures.replace('${memberId}', memberId.toString()); //this.configService.getApiUrl('addMember');
    const url = this.configService.getEndpointUrl(endpoint);

    return this.http.post(url, data);
  }

  getMemberDisclosures(memberId: number): Observable<any> {
    const endpoint = this.apiEndpoints.member.getMemberDisclosures.replace('${memberId}', memberId.toString()); //this.configService.getApiUrl('addMember');
    const url = this.configService.getEndpointUrl(endpoint);

    return this.http.get(url);
  }

  saveAllMemberDisclosures(memberDisclosures: any): Observable<any> {
    const endpoint = this.apiEndpoints.member.saveAllMemberDisclosures; //this.configService.getApiUrl('addMember');
    const url = this.configService.getEndpointUrl(endpoint);
    
    return this.http.post(url, memberDisclosures);
  }
  

  

  ngOnDestroy(): void {
    
  }
  

}