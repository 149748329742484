

 <!--Bootstrap Table-->
 <div class="inner-table">
  <div class=" bg-white shadow rounded">
      <table class="table  mb-0 table-center">
 
        <thead>
          <!--<th *ngFor="let column of columnsToDisplay" class="th-element"
          [ngClass]="{ 
            'action-cell-element': (column === 'showMemberDisclosure' || column === 'sponsorIdNo' || column === 'actions'), 
            'info-cell-element': (column !== 'showMemberDisclosure' && column !== 'sponsorIdNo' && column !== 'actions')
            }">
            <div class="header-content" style="display: flex; align-items: center;justify-content: center;">
              <span class="th-element-title" [innerHTML]="trans(columnsToDisplayNames[column])"></span> 
            </div>
          </th>-->
          <th *ngFor="let column of columnsToDisplayNames" class="th-element"
              [style.width]="column.width">
            <div class="header-content" style="display: flex; align-items: center; justify-content: center;">
              <span class="th-element-title" [innerHTML]="trans(column.label)"></span>
            </div>
          </th>
          
        </thead> 


          <tbody>
            <ng-container  *ngFor="let element of dataSource.filteredData; let i = index" [class.table-active]="expandedElementDisclosure === element " >
                <!--Colored line of member info -->
              <!--<tr> 
                  <td class="colored-table-element info-cell-element" style="/*padding-right: 20px;*/">
                    <img class="gender-img" src="/assets/images/masarat/member/dependent-{{element.gender.toLowerCase()}}.png" alt="gender-icon">
                  </td>
                  <td class="colored-table-element info-cell-element" >
                    <span class="badge bg-soft-info"> 
                      {{trans(element.relation)}}
                       </span>
                  </td>  
                  
                  <td class="colored-table-element info-cell-element" >
                    <span class="badge bg-soft-info"> 
                      {{trans(element.name)}}
                      </span>
                  </td>

                  <td class="colored-table-element action-cell-element">
                    <span class="badge bg-soft-info">
                    {{element.disclosureStats?.YESCount || 0}} 
                  </span>
                  </td>

                  <td class="colored-table-element action-cell-element">
                   
                  </td>
                  
                  
                  
                  <td class="colored-table-element action-cell-element"> 
                    <a *ngIf="element.insClass !== 'VIP'" 
                        class="badge bg-secondary insClass-badge">
                          <span class="ins-badge-conent">{{element.insClass}}</span>
                     </a>
                     <a *ngIf="element.insClass === 'VIP'" class="badge bg-soft-success  insClass-badge">
                          <span class="ins-badge-conent">{{element.insClass}}</span>
                     </a>                                           
                  </td>
              </tr>--> <!--END Colored line of member info -->



                <!--Colored line of member info -->
                <tr *ngIf="element.sponsorIdNo!==null"   class="minimized-row" > 
                  <td *ngFor="let column of columnsToDisplayNames" class="colored-table-element"
                    [style.width]="column.width">

                    <img 
                      *ngIf="column.infoElem(element).tag=='img'" 
                      [src]="column.infoElem(element).value" 
                      class="{{column.infoElem(element).class}}" 
                      alt="gender-icon"
                    >

                    <span *ngIf="column.infoElem(element).tag=='badge'"  
                      class="{{column.infoElem(element).class}}"> 
                      {{column.infoElem(element).value}}
                    </span>
                  </td>
                  
                </tr> <!--END Colored line of member info -->


                <!-- members info line with action-->
                <tr>
                  <td *ngFor="let column of columnsToDisplayNames"  class="td-container info-line"
                      [style.width]="column.width">

                    <ng-container *ngIf="column.key !== 'showMemberDisclosure'  && column.key !== 'actions';">
                      <div class="td-value">
                        <span class="td-value">{{trans(element[column.key])}}</span>
                      </div><!-- td value -->
                  </ng-container>
                        <!-- Member Disclosure DropDown Icon -->
                        <ng-container *ngIf="column.key == 'showMemberDisclosure';">
                          <div (click)="toggleExpandedDisclosure(element, i)"  class="toggle-element">
                            <a  class="btn btn-icon btn-pills btn-outline-primary toggle-orange">
                            <mat-icon 
                            [ngClass]="{'expanded':(expandedElementDisclosure === element) && !isclosing }"
                            class="toggle-icon toggle-icon-orange"
                            >{{expandedElementDisclosure === element ? 'keyboard_arrow_upward' :
                              'keyboard_arrow_down'}}
                              </mat-icon> 
                            </a>
                          </div>
                        </ng-container>
                        <!--  Sub Memeber DropDown Icon -->
                   
                        <div class="btn-group dropdown-primary "  *ngIf="column.key === 'actions' && (memberOnAction!==element)" style="cursor: pointer;">
                                         
                          <mat-icon data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >more_vert</mat-icon>
                        <div class="dropdown-menu">
                            <a href="javascript:void(0)" class="dropdown-item" (click)="editMember(element)">تعديل</a>
                            <div class="dropdown-divider"></div>
                            <a href="javascript:void(0)" class="dropdown-item" (click)="deleteMember(element)">حذف</a>
                          
              
                        </div>
                    </div>
                       

                        <div *ngIf="(memberOnAction===element) && column.key === 'actions'">
                          <a (click)="cancelActionMember()" href="javascript:void(0);" class="cancel-btn cancel-fix-btn">
                            <div style="display: flex;">
                              <mat-icon style="margin-right:10px; cursor:pointer">arrow_forward</mat-icon>
                              <b style="cursor: pointer;">الغاء</b>
                            </div>
                          </a>
                        </div>
                        

                      

                    

                      <ng-template #showMemberDisclosure>
                        <ng-container *ngIf="column.key !== 'showDependents'; ">
                          <!--<th mat-header-cell *matHeaderCellDef>{{columnsToDisplayNames[column]}}</th>-->
                          <th mat-header-cell *matHeaderCellDef><mat-icon style="color:#ff7e3b">assignment_add</mat-icon></th>
                          <td mat-cell *matCellDef="let element" (click)="toggleExpandedDisclosure(element, i)" >
                            <mat-icon  
                            class="toggle-icon "
                            [ngClass]="{'expanded':(expandedElementDisclosure === element) && !isclosing }"
                            style="color:#ff7e3b">{{expandedElementDisclosure === element ? 'keyboard_arrow_upward' :
                              'keyboard_arrow_down'}}</mat-icon> 
                          </td>
                        </ng-container>
                      </ng-template>
                    </td>
               </tr> <!-- END Members info line with action-->



            <!-- EXPANDED Line 1 Disclosuer-->
          <!-- <tr *ngIf="expandedElementDisclosure  &&  i === currentExpandedDisclosureIndex" [class.hidden]="expandedElementDisclosure !== element" [class.expanded]="expandedElementDisclosure === element"> 
              <td [attr.colspan]="columnsToDisplay.length">
                    <div [ngClass]="{'closed-toggle': isclosing }" class="disclosure-container-one" >
                    <div [class.hidden]="expandedElementDisclosure == null"
                          style="z-index: 2;"
                          [@detailExpand]="element == expandedElementDisclosure ? 'expanded' : 'collapsed'"
                          [@detailExpand]="(element === expandedElementDisclosure && i === currentExpandedDisclosureIndex) ? 'expanded' : 'collapsed'">
                    <app-member-disclosure [memberId]="element.id" [disclosure]="disclosureList" (disclosureStats)="setDisclosureStats($event)" class="disclosure-component" ></app-member-disclosure>
                </div>
                </div>
              </td>   
          </tr> -->


          <tr>
            <td [attr.colspan]="columnsToDisplayNames.length"
                [class.hidden-tr]="expandedElementDisclosure !== element" >
                
                    <!-- Expanded Content Here -->
                    <div 
                        [@detailExpand]="(element === expandedElementDisclosure && i === currentExpandedDisclosureIndex) ? 'expanded' : 'collapsed'"
                        [@visibilityToggle]="element === expandedElementDisclosure && i === currentExpandedDisclosureIndex ? 'visible' : 'hidden'"
                    >
                        <app-member-disclosure
                          
                            [memberId]="element.id"
                            [disclosure]="disclosureList"
                            (disclosureStats)="setDisclosureStats($event)"
                            >
                        </app-member-disclosure>
                    </div>
            </td>
        </tr>

     


      

        </ng-container>

          </tbody>
      </table>
  </div>
</div>

