export interface Sme {
    id: number;
    crName: null |string;
    crNumber: null | string;
    crEntityNumber: null | number;
    issueDate: null | string;
    expiryDate: null | string;
    crMainNumber: null | number;
    crStatusId: string;
    crStatusName: string;
    crStatusNameEn: string;
    cancellationDate: string;
    cancellationReason: string;
    website: string;
    address: string;
    email: string;
    fax: null | number;
    telephone: null | number;
    postalBox: null | number;
    buildingNumber: null | number;
    additionalNumber: null | number;
    streetName: string;
    city: string;
    zipcode: null | number;
    unitNumber: null | number;
    districtId: number;
    districtName: string;
    isEcommerce: boolean;
    locationId: number;
    locationName: string;
}

export interface SmeUser {
    id: number;
    sme: Partial<Sme> | null ;
    userId: number;
    anonymousUserId: string;
    journeyStatus: string;
    currentPolicyExpiryDate: string | null;
    newPolicyStartDate: string | null;
    mobileNumber: string;
}

// Default values
const defaultSme: Partial<Sme> = {
};

export const defaultSmeUser: SmeUser = {
    id: 0,
    sme: defaultSme,
    userId: 0,
    anonymousUserId: '',
    journeyStatus: '',
    currentPolicyExpiryDate: null,
    newPolicyStartDate: null,
    mobileNumber: ''
};
