<div class="member-file-container bg-light border-1" >
  

  <div class="download-blank-template"> 
    <!-- <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md"> -->
      <div class="step-container">
          <div class="features feature-primary feature-small" >
            <div class="image position-relative" style="max-width: 100px; max-height: 100px;">
                <!-- <img src="/assets/images/icon/one.png" class="avatar avatar-small" alt="step1" style="width: 10px;height: 10px;"> -->
                <h5 class="text-inside"> 
                  المرحلة الأولى
                  </h5>
            </div> 
          </div>

          <div class="content" style="margin-left: -12px; position: relative; transform: translate(-52.6%, -90%);">
            <a href="https://health.tameeni.com/Templates/Tameeni_Memberslist_Template.xlsx?V58.3" class="btn btn-secondary">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708z"/>
                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
              </svg>
              تنزيل النموذج من هنا
          </a>
       </div>
      </div>
      <p class="text-muted">                     
        <b style="font-size: 16px;">
         قم بتنزيل نموذج إكسل الفارغ واملأ التفاصيل 
       </b>
    </p>
  </div>
  
  <div class="upload-excel-file">
    <div class="step-container">
      <div class="features feature-primary feature-small" >
        <div class="image position-relative">
            <!-- <img src="/assets/images/icon/one.png" class="avatar avatar-small" alt="step1" style="width: 10px;height: 10px;"> -->
            <h5 class="text-inside"> 
              المرحلة الثانية
              </h5>
        </div> 
      </div>
      <div class="content">

        <input type="file" id="input-file" name="input-file"  accept=".xls,.xlsx" tabindex="-1" (change)="onFileSelected($event)"  hidden />
        <label class="btn-upload btn btn-secondary" for="input-file" style="width: 200px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
            <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z"/>
            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
          </svg>
         حمل الملف من هنا
        </label>
   </div>

      </div>
      <p class="text-muted">                     
        <b style="font-size: 16px;">
           قم بتحميل الملف بعد تعبئة جميع بيانات المؤمن لهم 
        </b>
    </p>
  </div>

</div>
