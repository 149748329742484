<header id="topnav" class="defaultscroll sticky large-screen  border-0" [ngClass]="{'white-background shadow': !isCurrentRouterHome}" style="z-index: 999;">
  <div class="header-container">
      <!-- Logo container-->
      <div class="logo-container">
          <a class="logo" routerLink="/home" *ngIf="isScroll || !isCurrentRouterHome">
              <img src="https://masarat-ins.com/asset/front/img/brand-sticky-green.png"
                class="green-logo"
                alt="masarat-brand-logo" >
          </a>
          <a class="logo" routerLink="/home" *ngIf="!isScroll">
            <img src="/assets/images/brand-logo-white.png" height="19" alt="masarat-brand-log" style="height: 60px; width: 100px;">
          </a>
      </div> <!-- End Logo container-->
      
      
    <div class="menu-item menu-toggle small-screen-only" (click)="toggleNav()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
        </svg>
    </div>
 
    <div id="navigation"  >
     
         
          <!-- Navigation Menu-->   
          <ul class="navigation-menu" >
            <li class="navigation-menu right-aligned-menu" *ngIf="isLoggedIn">
                <a routerLink="user-board" routerLinkActive="active" (click)="closeNav()" type="button" id="dropdownMenu1" aria-haspopup="true" aria-expanded="true" class="yellow-link profile-icon">
                    
                  <span class="nav-text profile" [ngClass]="{'white-color-text': isCurrentRouterHome && !isScroll}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                      <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                    </svg>
                    {{userName}}
                    
                  </span>
                </a>
              </li>
              <li><a routerLink="/home">
                <span class="nav-text" 
                      

                      [ngClass]="{'white-color-text': isCurrentRouterHome && !isScroll}">
                    الصفحة الرئيسية
                </span>
            </a>
            </li>
   
              <li class="has-submenu" style="cursor: pointer; padding: 0;">
                  <a>
                    <span class="nav-text" 
                         [ngClass]="{'white-color-text': isCurrentRouterHome && !isScroll}">

                        التأمين الطبي للمنشآت
                    </span>
                </a> 
                  <!-- <span class="menu-arrow" id="nav-item" *ngIf="isScroll " style="  border: solid rgb(255, 255, 255) !important;" ></span> -->
                  <span class="menu-arrow" id="nav-item" [ngClass]="{'black-color-arrow': (isCurrentRouterHome && isScroll) || !isCurrentRouterHome}"></span>
                  <ul class="submenu megamenu" style="width: 500px;">
                      
                              <li><a>التأمين صنف أ</a></li>
                              <li><a>التأمين صنف ب</a></li>
                              <li><a>التأمين صنف ج</a></li>
                              <li><a>المزيد</a></li>

                        
                    
                  </ul>
              </li>

              <li >
                  <a id="nav-item">
                    <span class="nav-text" 
                         [ngClass]="{'white-color-text': isCurrentRouterHome && !isScroll}">

                        الأسئلة الشائعة
    
                    </span>
                </a>
                  
              </li>
              <li >
                <a id="nav-item">
                    <span class="nav-text" 
                         [ngClass]="{'white-color-text': isCurrentRouterHome && !isScroll}">

                        تواصل معنا    
                    </span>
                    
                    </a>
                
            </li>
           

            <li> 
                <a id="nav-item">
                    <span class="nav-text" 
                         [ngClass]="{'white-color-text': isCurrentRouterHome && !isScroll}">
                         EN</span>
                
            </a></li>
            <li> <div class="monshaat-logo" style="height: 35px;"> </div></li>

          </ul><!--end navigation menu-->
          
      </div><!--end navigation-->
        <div class="loggin-container">
                <button class="btn btn-info" 
                *ngIf="!isLoggedIn"
                (click)="login()"
                > 
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/>
                    <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                  </svg>
                     دخول
                </button>
        </div>

        <div class="loggin-container" *ngIf="isLoggedIn">
            <button class="btn btn-info" (click)="logout()" >
                <span class="nav-text"  style="color: white;"
                     >
                     خروج</span>
                
            </button>
        </div>
  </div><!--end container-->
</header>
<!-- Header Small Screen-->
<header id="topnav" class="defaultscroll sticky  border-0 white-background shadow small-screen-only"  style="z-index: 999;">
        <!-- Logo container-->
        
        
      <div class="menu-item menu-toggle small-screen-only" 
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            (click)="toggleNav()">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
          </svg>
      </div>
      <div class="dropdown-menu">
        <a  class="dropdown-item" routerLink="user-board" *ngIf="isLoggedIn"> 
            {{userName}}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
              </svg>
        </a>
        <div class="dropdown-divider" *ngIf="isLoggedIn"></div>
        <a  class="dropdown-item" routerLink="/home">  
                              الصفحة الرئيسية
        </a>
        <a  class="dropdown-item">  التأمين الطبي للمنشآت</a>
        <a  class="dropdown-item"> الأسئلة الشائعة</a>
        <div class="dropdown-divider"></div>
        <a  class="dropdown-item"> تواصل معنا </a>
    </div>
   
          <div class="loggin-container" *ngIf="!isLoggedIn">
                  <button class="btn btn-info" 
                  (click)="login()"
                  > 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/>
                      <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                    </svg>
                       دخول
                  </button>
          </div>
  
          <div class="loggin-container" *ngIf="isLoggedIn">
              <button class="btn btn-info" (click)="logout()" >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                    <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                  </svg>
                       خروج
              </button>
          </div>

  </header>
  
  


  
  