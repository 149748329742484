

                    
    
              
                        
                            <div class="sidebar sticky-bar p-4 rounded shadow border border-0">
                                <div class="widget">
                                  
                                    <div class="row mt-4">
                                            <img src="assets/images/user/signup.svg" class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                                            <h5 class="widget-title" style="text-align: center;">{{userInformations.name}} </h5>
                                    </div><!--end row-->
                                </div>
                                <div class="widget mt-4">
                                    <ul class="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
                                        <!-- <li class="navbar-item account-menu px-0">
                                            <a routerLink="/1" 
                                            class="navbar-link d-flex rounded shadow align-items-center py-2 px-4 border border-1">إضافة مؤمن لهم </a>
                                        </li> -->

                                        <li class="navbar-item account-menu px-0" *ngFor="let section of sidebarSections" class="icon1" id="dashboardli">
                                            <a [routerLink]="section.route" 
                                                class="navbar-link d-flex rounded  align-items-center py-2 px-4 border border-1">
                                                <span class="h4 mb-0" >
                                                    <!-- <mat-icon style="margin-bottom: -5px; color:#212529;">{{section.icon}}</mat-icon> -->
                                                    <i class="{{section.icon}}"
                                                    style="margin-bottom: -5px; color:#212529;"></i>
                                                </span>
                                                <h6 class="mb-0 ms-2">{{section.title}}  </h6>
                                                    <span class="badge bg-success rounded-circle"  
                                                        *ngIf="section.badge>0">
                                                            {{section.badge}} 
                                                    </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

    
 