import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Routes } from '@angular/router';
import { SMEService } from '../services/sme.service';
import { SmeUser, Sme, defaultSmeUser } from '../models/sme-user';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { StepTabChildInterface } from 'src/app/shared/models/step-tab-child';
import { DateAdapter } from '@angular/material/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
@Component({
  selector: 'app-sme-data',
  templateUrl: './sme-data.component.html',
  styleUrls: ['./sme-data.component.scss', './sme-input.component.scss'],
  animations: [
    trigger('collapseAnimation', [
      state('collapsed', style({ height: '0px', overflow: 'hidden' })),
      state('expanded', style({ height: '*', overflow: 'hidden' })),
      transition('collapsed <=> expanded', animate('300ms ease-in-out')),
    ]),
    
  ]
})

export class SmeDataComponent implements StepTabChildInterface, OnInit, OnDestroy {

  smeCR: string|number = '';
  smeDataList: SmeUser = defaultSmeUser;
  
  standaloneComponent!: boolean;
  private inSmeSearchSubscription!: Subscription;
  private getMockData = false;

  constructor(
    private smeService: SMEService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private sessionStorageService: SessionStorageService, 
    private sharedDataService: SharedDataService,
    private datePipe: DatePipe,
   // private dateAdapter: DateAdapter<Date> // Inject DateAdapter
  ){
    this.sharedDataService.setInSmeData(true);
   // this.dateAdapter.setLocale('ar');
  }

  isCollapsed = true; // Initially collapsed

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  saveAllOnNextAction(): void {
    this.saveData();
  }

  ngOnInit(){
    this.inSmeSearchSubscription = this.sharedDataService.standaloneComponent$.subscribe((value) => {
      this.standaloneComponent = value;
    });
    
    // Retrieve SME ID from route parameters
    this.route.params.subscribe(params => {
      this.smeCR = this.sessionStorageService.getItem('CRNumber');
      
      // Use SmeDataService to fetch data
      this.getSMEUserData();
    });

  }

  getSMEUserData(): void {
    
    const smeUserId = this.sessionStorageService.getItem('smeUserId');
    this.smeService.getSmeUserById(smeUserId, this.getMockData).subscribe({
      next: (data) => {
      
        this.smeDataList = data;
        

        // Redirect to the 'sme' route with the SME's ID or any relevant information
        //this.router.navigate(['/sme', data.id]); // Adjust as per your data structure
      },
      error: (e) => console.error(e)
    });
  }
  
  passToMemberData(){
    // Redirect to the 'sme' route with the SME's ID or any relevant information
    //this.router.navigate([`/step-2`]); // Adjust as per your data structure
  }

  saveData(): void{

    this.smeDataList.newPolicyStartDate = this.datePipe.transform(this.smeDataList.newPolicyStartDate, 'yyyy-MM-dd');
    this.smeService.updateSmeUserById(
        this.sessionStorageService.getItem('smeUserId'),
        this.smeDataList
      ).subscribe({
      next: (data) => {
        
        console.log(data);

        // Redirect to the 'sme' route with the SME's ID or any relevant information
        //this.router.navigate(['/sme', data.id]); // Adjust as per your data structure
      },
      error: (e) => console.error(e)
    });
  }

  ngOnDestroy(){
    this.inSmeSearchSubscription.unsubscribe();
  }
}
