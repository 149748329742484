

<app-quotation-box *ngFor="let quotationElm of quotationList" [quotationBox]="quotationElm"></app-quotation-box>
<div class="container" >
  <div *ngIf="loading" class="loader-container">
    <span class="loader"></span>
    <span class="loader-text">
      جاري طلب الأسعار من شركات التأمين..
    </span>
  </div>
  
</div>


