import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Routes } from '@angular/router';
import { Member } from '../models/member';
import { MemberService } from '../services/member.service';
import { CommonService } from '../../shared/services/common.service';
import { DatePipe } from '@angular/common';
import { SessionStorageService } from '../../shared/services/session-storage.service';
import { PubSubService } from 'src/app/shared/services/pub-sub.service';
import { MemberDataList } from '../models/member-data';
//Demo : https://harvest-dev.github.io/ng-select2/dist/ng-select2/browser/
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component'; 
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { HttpStatusCode } from '@angular/common/http';
import * as moment from 'moment-hijri';  // Import moment-hijri


@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss', './member-input.component.scss'],
  
})


export class MemberComponent {
  openForm: boolean = false;
  @Output() openFormChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newAddedMember: EventEmitter<any> = new EventEmitter<any>();
  @Input() parentMemberDataList!: MemberDataList[];
  @ViewChild('idNoInput', { static: false }) idNoInput!: ElementRef;
  @ViewChild('govApiResponseError', { static: false }) govApiResponseError!: ElementRef;
  selectedMonth: string = '';
  selectedYear: string = '';

  member: MemberDataList = {
    id: 0,
    smeId: null,
    smeUserId: this.sessionStorageService.getItem('smeUserId'),
    idNo: '',
    birthDate: '',
    hijriBirthDate: '',
    age: null,
    sponsorIdNo: null,
    mobileNumber: '',
    nationality: '',
    name: '',
    gender: 'MALE',
    relation: 'EMPLOYEE',
    marital: 'SINGLE',
    insClass: 'C',
    dependentsCount: null,
    memberDisclosures: null,
    disclosureStats: null
  } ;


  // Dropdowns
  genderValues: string[] = [];
  relationValues: string[] = [];
  maritalValues: string[] = [];
  insClassValues: string[] = [];

  memberType: number = 1;
  memberNeedDobPrecision: boolean = false;
  isMarried: boolean = false;
  editMemberMode: boolean = false;
  deleteMemberMode: boolean = false;
  memberGovApiResponseError!: boolean;
  memberGovApiResponseErrorMessage!: string;
  memberGovApiResponseErrorTitle!: string;
  

  //Transform the parentMemberDataList to select2 dropdown type
  parentMemberDataListToSelect2Data: Select2Data = [
    {
        value: 'EX.: 00-0000-0000',
        label: 'EX.: 00-0000-0000',
        data: { idNo: 'رقم الهوية', name: 'الاسم', insClass: 'فئة التأمين' },
        templateId: 'template1',
        id: 'option-default',
    }
  ];

  //Transform the parentMemberDataList to select2 dropdown type
  hijriMonthsFormemberBirthDateSelect2: Select2Data = [
      { value: '01', label: 'محرم' },
      { value: '02', label: 'صفر' },
      { value: '03', label: 'ربيع الأول' },
      { value: '04', label: 'ربيع الآخر' },
      { value: '05', label: 'جمادى الأولى' },
      { value: '06', label: 'جمادى الآخرة' },
      { value: '07', label: 'رجب' },
      { value: '08', label: 'شعبان' },
      { value: '09', label: 'رمضان' },
      { value: '10', label: 'شوال' },
      { value: '11', label: 'ذو القعدة' },
      { value: '12', label: 'ذو الحجة' }
  ];

  maxMemberAge: number = 100; // This will be used to define the list of years for the member birth date 
  hijriYearsFormemberBirthDateSelect2: Select2Data = []; 
 
  


  constructor(
    private memberService: MemberService, 
    private commonService: CommonService, 
    private datePipe: DatePipe, 
    private sessionStorageService: SessionStorageService, 
    private refreshService: PubSubService,
  ){}

  ngOnInit(){
    this.loadList('gender', 'sme');
    this.loadList('relation', 'sme');
    this.loadList('marital', 'sme');
    this.loadList('ins-class', 'qt');
    this.generateHijriYears(1345, new Date().getFullYear());
    this.handleSelection();
  }

  init(){
    this.editMemberMode = false;
    this.deleteMemberMode = false;
    // Clear all filled input during edit/add action
  }


  // Generate Hijri years from the start year to the current year dynamically
  generateHijriYears(startYear: number, endYear: number) {
    const currentHijriYear = moment().iYear();
    
    for (let year = (currentHijriYear-this.maxMemberAge); year <= currentHijriYear; year++) {
      this.hijriYearsFormemberBirthDateSelect2.push({ value: year.toString(), label: year.toString() });
    }
  }

  // Update the selected birth date
  setMemberBirthDate() {
    const fullMemberBirthDate = this.selectedYear + '-' + this.selectedMonth;
    this.member.hijriBirthDate = fullMemberBirthDate;
    console.log('fullMemberBirthDate==>', fullMemberBirthDate);
  }

  // Handle update events for select2 month
  onMonthUpdate(event: Select2UpdateEvent<any>) {
    this.selectedMonth = event.value;
    this.setMemberBirthDate();
  }

  // Handle update events for select2 year
  onYearUpdate(event: Select2UpdateEvent<any>) {
    this.selectedYear = event.value;
    this.setMemberBirthDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['parentMemberDataList']) {
      //const updatedParentMemberDataList = changes['parentMemberDataList'].currentValue;
      // Perform your transformations on updatedParentMemberDataList and set it to parentMemberDataListToSelect2
      this.transformData();
    }
  }

  private transformData(): void {
    if(this.parentMemberDataList?.length>0){
      this.parentMemberDataListToSelect2Data = this.parentMemberDataList
      .filter(member => member.sponsorIdNo === null) // Filter based on sponsorIdNo
      .map(member => ({
        value: member.idNo?.toString() ?? '',
        label: member.idNo?.toString() ?? '',
        data: { idNo: member.idNo?.toString(), name: member.name, insClass: member.insClass },
        templateId: 'template1',
        id: 'option-'+member.idNo,
      }));
    }
    
  }

  sponsorIdNoUpdate(event: Select2UpdateEvent<any>): void{

  }


  loadList(listName: string, fromService: string){
    this.commonService.getListValues(listName, fromService).subscribe(values => {
      console.log(values);
      if (listName === 'gender' || listName === 'relation' || listName === 'marital') {
          (this as any)[listName + 'Values'] = values;
      } else if (listName === 'ins-class') {
          this.insClassValues = values;
      }
    });
  }

  addMember(): void{

    
    if(this.member.birthDate != null){
      this.member.birthDate = this.datePipe.transform(this.member.birthDate, 'yyyy-MM-dd');
    }
    /*console.log(" smeUserId : ", this.sessionStorageService.getItem('smeUserId'));
    console.log(" smeId : ", this.sessionStorageService.getItem('smeId'));
    console.log(" userId : ", this.sessionStorageService.getItem('userId'));
    console.log(" anonymousUserId : ", this.sessionStorageService.getItem('anonymousUserId'));*/
    
    this.memberService.addMember(this.member).subscribe({
      next: (res) => {
        
        this.openForm = false;
        
        // Perform the action that requires a refresh
        // After the action is performed, trigger the refresh
        const memberAddedSuccessfully = this.handleMemberGovApiResponse(res);
        if(memberAddedSuccessfully){
          // Emit the updated value of openForm to parent component (which include this component)
          this.openFormChange.emit(this.openForm);
          this.newAddedMember.emit(res);
          this.clearMemberAction();
        }
      },
      error: (e) => console.error(e)
    });

    this.init();
  }

  editMember(): void{

    if(this.member.birthDate != null){
      this.member.birthDate = this.datePipe.transform(this.member.birthDate, 'yyyy-MM-dd');
    }

    this.memberService.editMember(this.member).subscribe({
      next: (res) => {
        
        this.openForm = false;
        
        // Perform the action that requires a refresh
        // After the action is performed, trigger the refresh
        const memberAddedSuccessfully = this.handleMemberGovApiResponse(res);
        if(memberAddedSuccessfully){
          // Emit the updated value of openForm to parent component (which include this component)
          this.openFormChange.emit(this.openForm);
          this.newAddedMember.emit(res);
          this.clearMemberAction();
        }
        
      },
      error: (e) => console.error(e)
    });

    this.init();
  }

  deleteMember(): void{
    this.memberService.deleteMember(this.member).subscribe({
      next: (res) => {
        
        this.clearMemberAction();

        // After the action is performed, trigger the refresh
        this.refreshService.triggerRefresh();
      },
      error: (e) => console.error(e)
    });

    this.init();
  }

  newMember(): void{
        
    // Init 
    //this.memberIsSponsor = (this.member.sponsorIdNo === null); 

    this.openForm = true;
    // Emit the updated value of openForm to parent component (which include this component)
    this.openFormChange.emit(this.openForm);
    this.idNoInput?.nativeElement.focus();
    /*this.member = {
          smeId: this.sessionStorageService.getItem('smeId'),
          idNo: null,
          birthDate: null,
          sponsorIdNo: null,
          mobileNumber: null,
          nationality: '',
          name: '',
          gender: '',
          relation: '',
          marital: '',
          insClass:''
        }*/

  }

  clearMemberAction(){
    this.member = {
      id: 0,
      smeId: null,
      smeUserId: this.sessionStorageService.getItem('smeUserId'),
      idNo: '',
      birthDate: '',
      hijriBirthDate: '',
      age: null,
      sponsorIdNo: null,
      mobileNumber: '',
      nationality: '',
      name: '',
      gender: 'MALE',
      relation: 'EMPLOYEE',
      marital: 'SINGLE',
      insClass: 'C',
      dependentsCount: null,
      memberDisclosures: null,
      disclosureStats: null
    };

    
    this.editMemberMode = false;
    this.deleteMemberMode = false;
    this.handleSelection();
  }

  
  handleSelection(val?:any): void{
    
    let memberIsDependent = true;
    
    // رقم الكفيل
    if(this.member.relation==='EMPLOYEE' || val==='EMPLOYEE'){
      this.member.sponsorIdNo = null;
      memberIsDependent = false;
    }
    
    // رقم الهوية
    const idNumber = (this.member.idNo != null) ? this.member.idNo: '';    
    if (idNumber.startsWith('1') && !memberIsDependent) {
      this.memberNeedDobPrecision = true;
      // Perform actions for Case 1
    } else if (idNumber.startsWith('2')) {
      this.memberNeedDobPrecision = false;
      // Perform actions for Case 2
    } else if (idNumber?.startsWith('3') || idNumber.startsWith('4')) {
      this.memberNeedDobPrecision = false;
      // Perform actions for Case 3 or 4
    } else {
      this.memberNeedDobPrecision = false;
      // Perform actions for Other cases
    }
    // موظف / تابع
    
    
    
    
    // متزوج ؟
    //this.member.marital = (this.isMarried) ? this.maritalValues[0] : this.maritalValues[1];
    
  }

  handleMemberGovApiResponse(response: Member){
    console.log('response.memberGovApiResponse ==>', response.yakeenCheckError);
    if(response.yakeenCheckError != undefined && response.yakeenCheckError.statusCode != HttpStatusCode.Ok){
      console.log('response.memberGovApiResponse 1 ==>', response.yakeenCheckError);
      this.memberGovApiResponseError = true;
      this.memberGovApiResponseErrorMessage = response.yakeenCheckError.errorMessage;
      this.memberGovApiResponseErrorTitle = response.yakeenCheckError.errorTitle;
      this.idNoInput?.nativeElement.focus();

      return false;
    } else {
      console.log('response.memberGovApiResponse 2 ==>', response.yakeenCheckError);

      this.refreshService.triggerRefresh();
      return true;
    }

  }

  
    
}
  


