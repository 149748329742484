import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgSwitchCase } from '@angular/common';
import { AppConfigService } from 'src/app/shared/config/app-config.service';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  private apiEndpoints: any;

  constructor(private http: HttpClient, private configService: AppConfigService) { 
    this.apiEndpoints = configService.getApiEndpoints();
  }

  getListValues(listName: string, fromService: string): Observable<string[]> {
       
    var endpoint = null;
    
    switch(fromService) { 
        case 'sme': { 
            endpoint = this.apiEndpoints.sme.getList;
            break; 
        } 
        case 'qt': { 
            endpoint = this.apiEndpoints.quotation.getList; 
            break; 
        } 
        default: { 
            endpoint = null; 
            break; 
        } 
     }
    const preUrl = this.configService.getEndpointUrl(endpoint);
    const url = preUrl + '/' + listName.toLowerCase();
    return this.http.get<string[]>(url/*, { headers: headers }*/);
  }

  login() {
    const width = window.innerWidth * 0.25; // 20% of the current window's width
    const height = window.innerHeight * 0.8; // 60% of the current window's height
  
    // Calculate left and top position for centering the window
    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;
  
    // Open a new window with the route to LoginPopupComponent
    const newWindow = window.open('/auth', '_blank', `width=${width}, height=${height}, left=${left}, top=${top}, resizable=no`);
  
    // Focus the new window (optional)
    if (newWindow) {
      newWindow.focus();
    }
  }

  toggleCollapse(isCollapsed: boolean) {
    return !isCollapsed;
  }
}