import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { QuotationGroup } from 'src/app/quotation/models/quotation-group';
import { QuotationGroupStats } from 'src/app/quotation/models/quotation-group-stats';
import { QuotationService } from 'src/app/quotation/services/quotation.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Component({
  selector: 'app-quotation-groups',
  templateUrl: './quotation-groups.component.html',
  styleUrls: ['./quotation-groups.component.scss']
})
export class QuotationGroupsComponent {
  
  quotationGroups: QuotationGroupStats[] = [];
  

  constructor(
    private readonly keycloak: KeycloakService,
    private sessionStorageService: SessionStorageService,
    private quotationService: QuotationService,
    ) {
    
  }

  ngOnInit(): void {
    this.getActiveQuotation();
  }


  getActiveQuotation(){


    this.quotationService.getActiveQuotation().subscribe({
      next: (data) => {
        
        // Emit the updated data once received
        this.quotationGroups = data;
      
      },
      error: (e) => console.error(e)
    });
    
  }


  ngOnDestroy(): void {
    this.quotationService.sseStreamDestroy();
  }
}
