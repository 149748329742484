           <!-- Shape Start -->
           <!-- <div class="position-relative">
            <div class="shape overflow-hidden text-footer">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#026a7c"></path>
                </svg>
            </div>
        </div> -->  
        <!--Shape End-->

        <!-- Footer Start -->
        <footer class="footer">    
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-py-60">
                            <div class="row">
                                <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                    <a routerLink="/home" class="logo-footer">
                                        <img src="/assets/images/brand-logo-white.png" height="24" alt="masarat-brand-log" style="height: 60px; width: 100px;">
                                    </a>
                                    <p class="mt-4">
                                        مسارات التأمين (مسارات) هي شركة وساطة تأمين سعودية مقرها الرياض، مرخصة من هيئة التأمين، تعمل منذ 15 عام في خدمة قطاع المنشآت الصغيرة والمتوسطة والكبيرة.              
                                    </p>
                                    <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                        <li class="list-inline-item mb-0"><a href="https://www.facebook.com/shreethemes" target="_blank" class="rounded"><i class="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
                                        <li class="list-inline-item mb-0"><a href="https://www.instagram.com/shreethemes/" target="_blank" class="rounded"><i class="uil uil-instagram align-middle" title="instagram"></i></a></li>
                                        <li class="list-inline-item mb-0"><a href="https://twitter.com/shreethemes" target="_blank" class="rounded"><i class="uil uil-twitter align-middle" title="twitter"></i></a></li>
                                        <li class="list-inline-item mb-0"><a href="mailto:support@shreethemes.in" class="rounded"><i class="uil uil-envelope align-middle" title="email"></i></a></li>
                                    </ul><!--end icon-->
                                </div><!--end col-->
                        
                                <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">تصفح</h5>
                                    <ul class="list-unstyled footer-list mt-4">
                                        <li><a href="javascript:void(0)" class="text-foot"><i class="uil uil-angle-left-b me-1"></i> 
                                            الصفحة الرئيسية
                                        </a></li>
                                        <li><a href="javascript:void(0)" class="text-foot"><i class="uil uil-angle-left-b me-1"></i> الأسئلة الشائعة
                                        </a></li>
                                        <li><a href="javascript:void(0)" class="text-foot"><i class="uil uil-angle-left-b me-1"></i> 
                                            تواصل معنا
                                        </a></li>
                                        <li><a href="javascript:void(0)" class="text-foot"><i class="uil uil-angle-left-b me-1"></i> 
                                            أصناف التامين الطبي
                                        </a></li>
                                   

                                    </ul>
                                </div><!--end col-->
                        
                                <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">
                                        روابط تهمك
                                    </h5>
                                    <ul class="list-unstyled footer-list mt-4">
                                        <li><a href="javascript:void(0)" class="text-foot"><i class="uil uil-angle-left-b me-1"></i>                 
                                                  سياسة الخصوصية        
                                            
                                    </a></li>
                                        <li><a href="javascript:void(0)" class="text-foot"><i class="uil uil-angle-left-b me-1"></i> 
                                            الشروط والأحكام
                                        </a></li>
                                        <li><a href="javascript:void(0)" class="text-foot"><i class="uil uil-angle-left-b me-1"></i> 
                                            الأسئلة الشائعة
                                        </a></li>
                                     
                                    </ul>
                                </div><!--end col-->
    
                                <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">  النشرة الإخبارية  </h5>
                                    <p class="mt-4">   قم بالتسجيل واحصل على أحدث النصائح عبر البريد الإلكتروني.   </p>

                                    <form>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="foot-subscribe mb-3">
                                                    <label class="form-label">                      
                                                                      اكتب بريدك الإلكتروني
                                                        <span class="text-danger">*</span></label>
                                                    <div class="form-icon position-relative">
                                                        <i data-feather="mail" class="fea icon-sm icons"></i>
                                                        <input type="email" name="email" id="emailsubscribe" class="form-control ps-5 rounded" 
                                                        placeholder="البريد الالكتروني"
                                                        style="background-color: rgb(240, 240, 240); color: rgb(24, 24, 24) !important; "
                                                          required>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="d-grid">
                                                    <input type="submit" id="submitsubscribe" name="send" class="btn btn-soft-primary"  value="سجل الان"
                                                    style="background-color: #f7961c !important; color: white !important;"
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div><!--end col-->
                            </div><!--end row-->
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
            
            <div class="footer-py-20 footer-bar" style="background-color:rgb(44, 44, 44)  ;  padding: 10px;">
                <div class="container text-center">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <div class="text-sm-start">
                                <p class="mb-0">© {{currentYear}} كل الحقوق محفوظة  لصالح <a href="https://shreethemes.in/" target="_blank" class="text-reset">مسارات</a>.</p>
                            </div>
                        </div><!--end col-->

                        <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <ul class="list-unstyled text-sm-end mb-0">
                                <li class="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/american-ex.png" class="avatar avatar-ex-sm" title="American Express" alt=""></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/mada+.png" class="avatar avatar-ex-sm" title="Mada" alt=""></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/master-card.png" class="avatar avatar-ex-sm" title="Master Card" alt=""></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/paypal.png" class="avatar avatar-ex-sm" title="Paypal" alt=""></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/visa.png" class="avatar avatar-ex-sm" title="Visa" alt=""></a></li>
                            </ul>
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end container-->
            </div>
        </footer><!--end footer-->
        <!-- Footer End -->