<div class="quotatoion-container border-0">
<div *ngFor="let quotationGroup of quotationGroups" class="job-box job-primary  border-0 shadow rounded p-4 position-relative" style="background-color: white;">
    <div class="box-heading col col-lg-12">
        <h2 class="box-title">عروض أسعار لـ  : {{quotationGroup.smeUserSummary.smeCrName}}</h2>
    </div>
    <div class="box-body collapsed-content">
            <div class="row">
                <div class="col-lg-7">
                    <div class="row data-row px-2">
                        <div class="col-sm-5 label">الفئات التأمينية: </div>
                        <div class="col-sm-7 value" style="align-items: right;">  
                        

                            <div class="badges-container">
                                
                                <span class="tagcloud text-center" *ngFor="let entry of quotationGroup.smeUserSummary.insClassCount | keyvalue" [class.box]="true" >
                                    <a href="jvascript:void(0)" class="rounded" [class.bg-soft-success]="entry.key == 'VIP'" [class.bg-soft-footer]="entry.key !== 'VIP'" >{{ entry.key }} : {{ entry.value }}</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row data-row px-2 no-gutters">
                        <div class="col-sm-5 label">رقم الحاسب/رقم الكفيل:</div>
                        <div class="col-sm-7 value">{{quotationGroup.smeUserSummary.smeCrEntityNumber}}</div>
                    </div>
                    <div class="row data-row px-2 no-gutters">
                        <div class="col-sm-5 label">عدد المؤمن لهم:</div>
                        <div class="col-sm-7 value">
                            {{ (+quotationGroup.smeUserSummary.sponsorsMemberCount || 0) + (+quotationGroup.smeUserSummary.dependentsMemberCount || 0) }}
                            (الموظفين: {{quotationGroup.smeUserSummary.sponsorsMemberCount}} التابعيين: {{quotationGroup.smeUserSummary.dependentsMemberCount}})
                        </div>
                    </div>
                    <div class="row data-row px-2 no-gutters">
                        <div class="col-sm-5 label">عدد التسعيرات:</div>
                        <div class="col-sm-7 value">{{quotationGroup.quotationCount}}</div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="row data-row">
                        <div class="col-sm-5 label">الرقم المرجعي للتسعيرة:</div>
                        <div class="col-sm-7 value">{{quotationGroup.id}}</div>
                    </div>
                    <div class="row data-row">
                        <div class="col-sm-5 label">رقم السجل التجاري:</div>
                        <div class="col-sm-7 value">{{quotationGroup.smeUserSummary.smeCrNumber}}</div>
                    </div>
                    <div class="row data-row">
                        <div class="col-sm-5 label">تاريخ بداية الوثيقة:</div>
                        <div class="col-sm-7 value">{{quotationGroup.smeUserSummary.newPolicyStartDate}}</div>
                    </div>
                    <div class="row data-row">
                        <div class="col-sm-5 label">تاريخ إصدار التسعيرة:</div>
                        <div class="col-sm-7 value">{{quotationGroup.createdAt}}</div>
                    </div>
                </div>
            </div>
    </div>
    <div class="box-footer">
        <div class="clock">
            <b>الوقت المتبقي لإنتهاء صلاحية العرض:</b>
            <span class="countdown" id="3414606" style="display: block;">
                <span>0</span> يوم <span>12</span> ساعة <span>18</span> دقيقة <span>41</span> ثانية
            </span>
        </div>
        <a href="/2/{{quotationGroup.id}}" class="btn btn-small btn-info mt-2 mb-1">معاينة التسعيرات</a>
    </div>
</div>
</div>