<div class="col-sm-12">
    <div class="d-flex justify-content-center mt-5">
        <h2>ادخل رمز التحقق</h2>
    </div>
    <div class="d-flex justify-content-center mt-2" id="divValidateMobile" ondragover="if (!window.__cfRLUnblockHandlers) return false; allowDrop(event)">
        <div class="col-sm-12 col-md-12 text-center">
            <div class="comp-subtext">يرجى إدخال رمز التحقق المرسل لجوالك برسالة نصية <span id="spnMobNo"></span></div>
            <div class="mobile-success ">
                <div class="alert green-mesg p-2 bg-success text-white" id="SuccessMessage" style="display: none;">

                </div>
            </div>
            <div class="form-group row justify-content-center mt-3">
                <!-- Form Label -->
                <label class="col-sm-2 col-form-label">ادخل رمز التحقق</label>
                <!-- Form Input -->
                <div class="col-sm-3">
                    <input [(ngModel)]="otp" type="tel" class="numberonly form-control" id="txtOTP" placeholder="" maxlength="6" autocomplete="off">
                    <div class="error-custom-mesg" id="InvalidOTP">
                    </div>
                    <div class="error-custom-mesg" id="LeftEttempts">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-5">
                <button (click)="confirmOtp()" type="button" id="btnVerify" class="btn btn-style-1">التأكيد</button>
            </div>
            <div class="d-flex justify-content-center mt-5" style="display: inline-flex;">
                اضغط&nbsp;<span class="text-primary">إعادة إرسال</span>&nbsp;
                إذا لم تستلم رمز التوثيق برسالة نصية خلال&nbsp; <div class="time-section"></div>

                <div class="time-countdown-box">
                    <div class="time-counter">
                        <div id="clock"></div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center mt-3">
                <button (click)="resendOtp()" type="button" class="btn btn-style-2" id="btnResend">إعادة إرسال</button>
            </div>
        </div>
    </div>
</div>