import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, tap, take, Subscription, Subject } from 'rxjs';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { UserInfos } from '../models/user-infos';
import { UserUpdateAccount } from '../models/user-update-account';
import { UserUpdatePassword } from '../models/user-update-password';
import { UserUpdatePhoneNumber } from '../models/user-update-phoneNumber';
import { UserUpdateEmail } from '../models/user-update-email';
import { AppConfigService } from 'src/app/shared/config/app-config.service';
import { ApiEndpoints } from 'src/app/shared/config/api-endpoints.interface';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  private apiEndpoints: ApiEndpoints;
  
  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.apiEndpoints = configService.getApiEndpoints();
 }

  getUserInfos(): Observable<any> {
    
    const url = this.configService.getEndpointUrl(this.apiEndpoints.users.getInfos);
    return this.http.get(url);
  }

  updateUserInfos(userUpdateInfos: UserUpdateAccount): Observable<any> {
    const endpoint = this.apiEndpoints.users.updateAccount;
    const url = this.configService.getEndpointUrl(endpoint);
    
    return this.http.post(url, userUpdateInfos);
      
  }

  updateUserPassword(userUpdatePassword: UserUpdatePassword): Observable<any> {
    const endpoint = this.apiEndpoints.users.updatePassword;
    const url = this.configService.getEndpointUrl(endpoint);
    
    return this.http.post(url, userUpdatePassword);
      
  }

  requestPhoneUpdate(userUpdatePhoneNumber: UserUpdatePhoneNumber): Observable<any> {
    const endpoint = this.apiEndpoints.users.requestPhoneUpdate;
    const url = this.configService.getEndpointUrl(endpoint);
    
    return this.http.post(url, userUpdatePhoneNumber);
      
  }

  verifyPhoneUpdate(otp: String): Observable<any> {
    const endpoint = this.apiEndpoints.users.verifyPhoneUpdate;
    const url = this.configService.getEndpointUrl(endpoint);
    console.log("otp==>", otp);
    return this.http.post(url, {"code": otp});
      
  }

  updateUserEmail(userUpdateEmail: UserUpdateEmail): Observable<any> {
    const endpoint = this.apiEndpoints.users.updateEmail;
    const url = this.configService.getEndpointUrl(endpoint);
    
    return this.http.post(url, userUpdateEmail);
      
  }
  
}
  
  

