
<div class="row">
    <div class="col-12 mt-4 pt-2" >
        <div class="pricing pricing-primary business-rate d-md-flex align-items-center border-0 shadow rounded p-4 ps-0 position-relative slide-in " style="background-color: white;">
            <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span class="text-center d-block shadow small h6">VIP</span></div>
            
            <div class="col-2 text-center border-end">
                <div>
                    <img src="/assets/images/masarat/quotation/ins-company-logos/{{quotationBox.insCompany.code}}.png" alt="{{quotationBox.insCompany.name}}" >
                </div>
                <div>
                    <a  class="title text-dark h6">{{quotationBox.insCompany.name}}</a>
                </div>
                
            </div>
            
            <div class="col-6 ms-md-4 mt-4 mt-sm-0 border-end">
                
                <div class="quote-details">
                    <div class="quote-price top-0 pb-2 border-bottom end-0 mt-3 me-3"  >
                        <h4 class="title text-muted h5">وثائق الشركات الصغيرة و المتوسطة</h4>
                    </div>
                    
                    
                    <ul class="list-unstyled text-muted">
                        <li class="mb-1">
                            <span class="text-primary h5 me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                    <defs></defs><g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" ><path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" /></g>
                                </svg>
                            </span>شبكة طبية متنوعة تضم اكثر من 500 مقدم رعاية صحية حول المملكة
                        </li>
                        <li class="mb-1">
                            <span class="text-primary h5 me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                    <defs></defs><g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" ><path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" /></g>
                                </svg>
                            </span>خدمة عملاء متاحة على مدار الساعة لإستفسارات العملاء وخدمتهم
                        </li>
                        <li class="mb-1">
                            <span class="text-primary h5 me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                    <defs></defs><g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" ><path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" /></g>
                                </svg>
                            </span>سرعة و مرونة في تقديم خدمات مابعد البيع
                        </li>
                        <li class="mb-1">
                            <span class="text-primary h5 me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                    <defs></defs><g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" ><path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" /></g>
                                </svg>
                            </span>مزايا اخرى
                        </li>
                    </ul>
                    <!--<ul class="quote-features">
                        <li>شبكة طبية متنوعة تضم اكثر من 500 مقدم رعاية صحية حول المملكة</li>
                        <li>خدمة عملاء متاحة على مدار الساعة لإستفسارات العملاء وخدمتهم</li>
                        <li>سرعة و مرونة في تقديم خدمات مابعد البيع</li>
                    </ul>-->
                </div>

                
            </div>

            <div class="w-100 ms-4 mt-4 mt-sm-0" >

                <div class="quote-price p-2 pb-2">
                    
                    <div class="row text-muted">
                        <small class="col-8"><b>المجموع (بدون ضريبة):</b></small>
                        <small class="col-4">
                            {{quotationBox.priceBeforeVat | number:'1.2-2'}} ر.س
                        </small>
                    </div>
                    <div class="row text-muted">
                        <small class="col-8">
                            <b>
                                +ضريبة القيمة المضافة
                                (15.00%):
                            </b>
                        </small>
                        <small class="col-4">
                            {{quotationBox.vatAmount | number:'1.2-2'}} ر.س
                        </small>
                    </div>
                </div>


                <div class="quote-price p-2 pb-2 border-top">
                    <div class="row text-muted">
                    </div>
                    <div class="row text-muted">
                        <small class="col-8"><b>المجموع:</b></small>
                        <small class="col-4 badge bg-soft-primary total-price-badge">
                            {{quotationBox.price | number:'1.2-2'}} ر.س
                        </small>
                    </div>
                    
                </div>

                <div class="quote-price p-2 pb-2 pt-1">
                    
                    <button (click)="selectQuotation(quotationBox.id)" class="btn btn-soft-secondary btn-lg position-absolute bottom-0 end-0 mt-3 me-3 mb-3" >اختيار</button>
                </div>
                
                <!--<div class="pt-1 border-top">
                    <div class="total-price">
                        <span class="text-muted h6 mb-0 me-lg-2">         

                        المجموع: 
                            </span>
                        <span class="badge bg-soft-warning" style="padding: 8px;">
                            {{quotationBox.price | number:'1.2-2'}} ر.س

                        </span>
                    </div>
                    <button (click)="selectQuotation(quotationBox.id)" class="btn btn-soft-secondary btn-lg position-absolute bottom-0 end-0 mt-3 me-3 mb-3" >اختيار</button>
                </div>-->
                
            </div>
        </div>
    </div><!--end col-->
</div>
