import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MemberDataList } from '../../models/member-data';
import { MatTableDataSource } from '@angular/material/table';
import { Disclosure } from '../../models/disclosure';
import { MemberDisclosureComponent } from '../member-disclosure/member-disclosure.component';
import { MemberDisclosure } from '../../models/member-disclosure';
import { MemberTableUtilityService } from '../../services/member-table-utility-service';

@Component({
  selector: 'app-member-data-row',
  templateUrl: './member-data-row.component.html',
  styleUrls: ['./member-data-row.component.scss', './thead.component.scss', './table.component.scss'],
  animations: [
    // Using the animation methods from the service
    MemberTableUtilityService.prototype.getDetailExpandAnimation(),
    MemberTableUtilityService.prototype.getDetailExpandMemberAnimation(),
    MemberTableUtilityService.prototype.getVisibilityToggleAnimation(),
  ]
})
export class MemberDataRowComponent implements OnInit {
  
  @Input()  dataSource!: MatTableDataSource<any>;
  @Input()  disclosureList!: Disclosure[];
  @Output() editMemberAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteMemberAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelMemberAction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren(MemberDisclosureComponent) memberDisclosureComponents!: QueryList<MemberDisclosureComponent>;


  allMemberDisclosures!: { [memberId: number]: MemberDisclosure[] };
  expandedElementDisclosure: any | null = null;
  expandedElementMember: any | null = null;
  columnsToDisplayNames: any[] = [];
  // member actions
  memberOnAction?: any;

  constructor(private memberTableUtilityService: MemberTableUtilityService){

  }
  
  ngOnInit(): void{
    this.columnsToDisplayNames = this.memberTableUtilityService.getColumnsConfig(true);
  }

  // Toogles : Disclosures / Member dependents
  currentExpandedDisclosureIndex : number | null = 0;
  isclosing=false;
  
  toggleExpandedDisclosure(element: any, index:number | null ): void {
    if (this.expandedElementDisclosure === element) {
      this.isclosing=true;
      setTimeout(() => {
        this.isclosing=false;
        this.currentExpandedDisclosureIndex = -1;
        this.expandedElementDisclosure = null; // Close if already open
      }, 600);
    } else {
        this.isclosing= false;
        this.expandedElementDisclosure = element; // Open the selected element
        this.currentExpandedDisclosureIndex = index;
    }
    
    
  }

  editMember(member: any): void{
    this.memberOnAction = member;
    this.editMemberAction.emit(member);
  }

  deleteMember(member: any): void{
    this.memberOnAction = member;
    this.deleteMemberAction.emit(member);
  }

  cancelActionMember(): void{
    this.memberOnAction = undefined;    
    this.cancelMemberAction.emit();
  }

  trans(memberAttribute: string): any{
    return this.memberTableUtilityService.trans(memberAttribute); 
  }

  getAllDependentDisclosures(): any {
    
    // Clear allMemberDisclosures
    this.allMemberDisclosures = {};

    if (this.memberDisclosureComponents) {
      this.memberDisclosureComponents.forEach(component => {
        const memberId = component.memberId;
        if (!this.allMemberDisclosures[memberId]) {
          this.allMemberDisclosures[memberId] = [];
        }
        this.allMemberDisclosures[memberId].push(...component.memberDisclosure);
      });
      return this.allMemberDisclosures;
      
    }
  }

  setDisclosureStats(event: any) {
    this.dataSource = this.memberTableUtilityService.setDisclosureStats(event, this.dataSource);
  }


}
