import { Injectable, OnDestroy } from '@angular/core';
import { Order } from '../models/order';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { AppConfigService } from 'src/app/shared/config/app-config.service';


@Injectable({
  providedIn: 'root'
})

export class PaymentService {

    private sseStream!: Subscription;
    private apiEndpoints: any;
  
    constructor(private http: HttpClient, private configService: AppConfigService) { 
      this.apiEndpoints = configService.getApiEndpoints();
    }

    prepareCheckout(data: any): Observable<any>{
        const endpoint = this.apiEndpoints.payments.prepareCheckout;
        const url = this.configService.getEndpointUrl(endpoint);

        return this.http.post(url, data);
    }

    getPaymentStatus(checkoutId: string): Observable<any>{
        const endpoint = this.apiEndpoints.payments.getPaymentStatus.replace('${checkoutId}', checkoutId);
        const url = this.configService.getEndpointUrl(endpoint);

        return this.http.get(url);
    }

    getTransactionByCheckoutId(checkoutId: string): Observable<any>{
        const endpoint = this.apiEndpoints.payments.getTransactionByCheckoutId.replace('${checkoutId}', checkoutId);
        const url = this.configService.getEndpointUrl(endpoint);

        return this.http.get(url);
    }
}