<!--accordion based box-->
<div class="accordion-item ">
  <div class="accordion-header" id="headingTwo">
      <button 
          class="accordion-button collapsed  border-1 rounded collapsed"
          [ngClass]="{'border-0': true, 'rounded': isCollapsed}"
          type="button"
          
           aria-controls="collapseSmeDataContent"
          data-bs-toggle="collapse" 
          data-bs-target="#collepseSmeDataContent"
      
          style="cursor: pointer;"
          (click)="toggleCollapse()"
          aria-controls="collepseSmeDataContent">
          <div class="btn-content">
            <div class="right-site-btn-content">
              <i class="mdi mdi-office-building"></i>
            <span class="btn-title">
                تفاصيل الشركة
            </span>
            </div>
            <div class="left-site-btn-content">
              <i class="mdi" [ngClass]="{'mdi-chevron-down': isCollapsed, 'mdi-chevron-up': !isCollapsed}"></i>
            </div>
          </div>
      </button>
  </div>

    <div id="collepseSmeDataContent"  
        class="accordion-collapse " 
       
  
        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        <!--accordion body ( content ) -->
        <div class="accordion-body  border-1  p-lg-5 p-4"   style="background: url('assets/images/hosting/domain.png') center center;  ">
          <!-- <div class="bg-overlay"></div> --> 
                <div class="row ">
                  
                  <div class="col col-lg-4 col-md-6 col-sm-12">
                    
                    <div class="mb-3"> <!-- for margin bottom spacing-->
                      <label class="form-label" >
                        رقم السجل التجاري للشركة أو الرقم الموحد للمنشأة
                        <span class="text-danger">*</span>
                      </label>
                      <div class="form-icon-1">
                          <i data-feather="user" class="uil uil-user"></i>
                            <input name="name" 
                                  id="CompanyCRNoORComputerNoEdit" 
                                  type="text" 
                                  [value]="smeDataList.sme?.crNumber"
                                  class="form-control form-control-1 disabled-form"  
                                  placeholder="xxxxxxxxxx/70xxxxxxxx"
                                  disabled
                            >
                      </div>
                  </div>
                </div>
                  <div class="col col-lg-4 col-md-6 col-sm-12">
                    <div class="form-row">
                      <div class="mb-3">
                      <label class="form-label">
                        اسم الشركة باللغة العربية
                        <span class="text-danger">*</span>
                      </label>
                      <input 
                      type="text" 
                      [value]="smeDataList.sme?.crName"
                      class="form-control ps-3 disabled-form" 
                      disabled
                    >
                      </div>
                    </div>
                  </div>

                  <div class="col col-lg-4 col-md-6 col-sm-12">
                    <div class="mb-3">
                      <label class="form-label">
                        اسم الشركة باللغة الإنجليزية<span class="text-danger">*</span>
                      </label>
                      <input 
                          [value]="smeDataList.sme?.crName"
                          type="text" 
                          class="form-control ps-3" 
                          
                          data-val="true" placeholder="اسم الشركة باللغة الإنجليزية" 
                      >              
                    </div>
                </div>
        
            <div class="col col-lg-4 col-md-6 col-sm-12">
                  <div class="mb-3">
                    <label class="form-label">
                    رقم الحاسب الآلي
                    </label>
                    <input 
                        type="text" 
                        [value]="smeDataList.sme?.crEntityNumber" 
                        class="form-control ps-3 disabled-form" 
                        disabled
                    >
                  </div>
              </div>
        
              <div class="col col-lg-4 col-md-6 col-sm-12">
                <div class="mb-3">
                  <label>اختر تاريخ بداية الوثيقة</label> 
                  <div class="form-icon">
                    <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <input name="date" type="text" class="form-control ps-5" style="padding-right: 40px;"
                    [matDatepicker]="datepicker"
                    [(ngModel)]="smeDataList.newPolicyStartDate"
                    placeholder="تاريخ بداية الوثيقة" >
                  </div>
                  
                  
                        <mat-datepicker #datepicker class="datepicker">
                          <mat-datepicker-actions>
                            <button class="btn btn-danger btn-sm" matDatepickerCancel >ألغاء</button>
                            <button class="btn btn-soft-info btn-sm" matDatepickerApply style="margin-right: 8px;">مصادقة</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                
                </div>
            </div>
        
            <div class="col col-lg-4 col-md-6 col-sm-12">
              <div class="mb-3">
                  <label class="form-label">
                    رقم الجوال<span class="text-danger">*</span>
                  </label>
                  <input 
                      class="form-control ps-0" 
                      placeholder="05XXXXXXXX" 
                      type="number" 
                      [(ngModel)]="smeDataList.mobileNumber"
                  >
                </div>
          </div>
                  
                </div>



        </div>
    </div>
</div>
