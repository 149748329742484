<section class="bg-profile d-table w-100 bg-primary " style="background: url('assets/images/hosting/domain.png') 50% 78%; " >
  <div class="step-card-container">
              <div class="card step-box border-0 rounded shadow fade-in " style="z-index: 100;">
                  <div class="card-body"> 
                    <!-- <div class="progress-box border border-1 rounded shadow p-lg-5 p-4"> -->
                      <div class="row align-items-end">
                        <div class="col-md-10 col-sm-12">
                          <div class="steps-container">
                            <div *ngFor="let tab of tabs; let i = index"
                              class="step-item"
                              [attr.data-iscurrent]="tab.isCurrent"
                              [attr.data-stepid]="tab.stepId"
                              [ngClass]="{ 'active': stepId >= tab.stepId, 'disabled': stepId < tab.stepId }"
                              role="tab">
                              <div class="step-wrap">
                                <div class="step">
                                  <span style="transform: translateY(2%)">{{ i + 1 }}</span>
                                    
                                 
                                </div>
                                <div class="title">{{ tab.title }}</div>
                                <div class="mobile-title">{{ tab.title }}</div>
                              </div>
                            </div>
                          </div>
                        
                          <div class="progress">
                            <div class="progress-bar position-relative bg-primary" style="{{progressBarWidth}}"></div>
                            <div id="prog" class="progressbar"></div>
                          </div>
                        </div>
                        
                        
                
                          <!--Next step button-->
                          <div class="col-md-2 col-sm-12 mt-4 mt-sm-0 ">
                            <div class="text-md-end text-center"   >
                                <a *ngIf="hideBtnNext"
                                  class="btn btn-info btn-next-step disabled" 
                                  (click)="passToStep(btnNextTargetStepId)">
                                    {{btnNextTargetStepTitle}}
                                    <i class="mdi mdi-chevron-left align-middle"></i>
                                </a>

                                <a *ngIf="!hideBtnNext"
                                  class="btn btn-info btn-next-step" 
                                  (click)="passToStep(btnNextTargetStepId)">
                                    {{btnNextTargetStepTitle}}
                                    <i class="mdi mdi-chevron-left align-middle"></i>
                                </a>
                            </div>
                        </div>
                      </div>
                    <!-- </div>   -->
                  </div>
              </div>
        
          
  </div><!--ed container-->
</section><!--end section-->




<div class="step-container  "> 
  <!-- progress box -->
  

  <!--components-container -->
  <div class="main-container"> 
    <!-- **************************** component ***********************************-->
    <div #componentContainer></div>   
    <div class="blocks-container row">   
            <div class="col col-lg-10" ></div>
                     <div class="button-container col col-lg-2"  style="padding-left: 0;">
                      <a *ngIf="!hideBtnNext"
                                  class="btn btn-info btn-next-step" 
                                  (click)="passToStep(btnNextTargetStepId)">
                                    {{btnNextTargetStepTitle}}
                                    <i class="mdi mdi-chevron-left align-middle"></i>
                                </a>
                     </div>
    </div>
    
  </div>


</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
