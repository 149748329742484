import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface StreamData {
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class EventSourceService {

  constructor() { }


  // Documentation: https://rxjs.dev/guide/observable#disposing-observable-executions
  observeMessages(url: string): Observable<StreamData> {
    //return new Observable<StreamData>();
    return new Observable<StreamData>(observer => {
      const es = new EventSource(url);
      es.addEventListener('message', (evt: StreamData) => {
        // console.log(evt.data);
        observer.next(evt.data != null ? JSON.parse(evt.data) : evt.data);
      });
      return () => es.close();
    });
  }
  
  
}