import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Quotation } from '../../../models/quotation';
import { KeycloakService } from 'keycloak-angular';
import { PubSubService } from 'src/app/shared/services/pub-sub.service';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-quotation-box',
  templateUrl: './quotation-box.component.html',
  styleUrls: ['./quotation-box.component.css']
})
export class QuotationBoxComponent {
  @Input() quotationBox!: Quotation;
  isLoggedIn = false;


  constructor(private readonly keycloak: KeycloakService, private pubSubService: PubSubService, private commonService: CommonService){}

  selectQuotation(quotationId: number): void{
    this.keycloak.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        // User is logged in, perform necessary actions
        this.pubSubService.selectQuotationAction(quotationId);
      } else {
        // User is not logged in or login failed
        this.commonService.login();
      }
    });
  }
  
  
}
