import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private keycloak: KeycloakService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const bearerToken = this.keycloak.getKeycloakInstance().token;

    // Clone the request and add the Authorization header
    if (bearerToken) {
      console.log('RequestInterceptor: bearerToken =>', `${bearerToken}`);

      // Check if the request is for a file upload
      if (request.body instanceof FormData) {
        // If it's a file upload, don't set the content type header
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
      } else {
        // For other requests, set the default content type header
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust content type if needed
          },
        });
      }
    }

    return next.handle(request);
  }
}
