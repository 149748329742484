import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

//@TODO: merge it into pub-sub.service class
export class SharedDataService {
  private inSmeDataSubject = new BehaviorSubject<boolean>(false);
  public standaloneComponent$: Observable<boolean> = this.inSmeDataSubject.asObservable();

  setInSmeData(value: boolean) {
    this.inSmeDataSubject.next(value);
  }

  getInSmeData(): boolean {
    return this.inSmeDataSubject.getValue();
  }

  
}

