import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakInstance } from 'keycloak-js';
import { QuotationService } from '../quotation/services/quotation.service';
import { SessionStorageService } from '../shared/services/session-storage.service';
import { Quotation } from '../quotation/models/quotation';
import { Router } from '@angular/router';
import { QuotationGroup } from '../quotation/models/quotation-group';

@Component({
  selector: 'app-user-board',
  templateUrl: './user-board.component.html',
  styleUrls: ['./user-board.component.css']
})
export class UserBoardComponent {
  private keycloakAuth!: KeycloakInstance;
  isLoggedIn = false;
  userInformations: any;

  // Sidebar 
  // Define links for navigation
  sidebarSections = [
    { label: 'InsPolicies', title: 'الوثائق المشتراة', route: 'ins-policies', icon: 'mdi mdi-file-document-multiple', badge: 0 },
    { label: 'quotationGroups', title: 'التسعيرات الحالية', route: 'quotation-groups', icon: 'mdi mdi-clipboard-list', badge: 0 },
    { label: 'paymentReceipts', title: 'إيصالات الدفع', route: '/content/link2', icon: 'mdi mdi-cash-check', badge: -1 },
    { label: 'userSettings', title: 'إعدادات', route: 'settings/infos', icon: 'mdi mdi-account-tie', badge: -1 }
    // Add more links as needed
  ];

  constructor(
    private readonly keycloak: KeycloakService,
    private sessionStorageService: SessionStorageService,
    private quotationService: QuotationService,
    private router: Router // Inject the Router service
    ) {
    
  }

  ngOnInit(): void {
    
    this.checkLoginStatus();
    this.keycloakAuth = this.keycloak.getKeycloakInstance();
    // Check if the user is authenticated before making the request
    if (this.keycloakAuth.authenticated) {
      // Get the token
      const token = this.keycloakAuth.token;
      this.isLoggedIn = true;
    }
    this.userInformations = this.isLoggedIn ? this.keycloakAuth.idTokenParsed : {};

    // Init Data
    this.getCountActiveQuotation();
  }

  checkLoginStatus(): void {
    this.keycloak.isLoggedIn().then((loggedIn) => {
      if (!loggedIn) {
        this.router.navigate(['/home']);
      } 
    });
  }

  getCountActiveQuotation(){

    const quotationIndex = this.sidebarSections.findIndex((section) => section.label === 'quotationGroups');

    this.quotationService.getCountActiveQuotation().subscribe({
      next: (count) => {
        
        // Emit the updated data once received
        this.sidebarSections[quotationIndex].badge = count;
        
        
        // Redirect to the 'sme' route with the SME's ID or any relevant information
        //this.router.navigate(['/sme', data.id]); // Adjust as per your data structure
      },
      error: (e) => console.error(e)
    });
    
  }
}
