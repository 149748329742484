
        <div class="container-fluid">
            <div class="row " style="margin-left: 10px;">
                <div class="col-lg-3">
                    <app-user-board-sidebar [sidebarSections]="sidebarSections" [userInformations]="userInformations"></app-user-board-sidebar>
                </div>    
                
                <div class="col-lg-9 pt-4 pb-4 user-board-content shadow border border-0 rounded" style="margin-top: 100px;  background-color: #fff !important;">
                        
                          
                               
                                    <router-outlet></router-outlet>    
                            
                        
                       
        </div>
