import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { SMEService } from '../services/sme.service';
import { SmeUser } from '../models/sme-user';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { KeycloakService } from 'keycloak-angular';


@Component({
  selector: 'app-sme',
  templateUrl: './sme.component.html',
  styleUrls: [
    './sme.component.css'
  ],
})

export class SMEComponent implements OnInit, OnDestroy {
  
  [x: string]: any;
  smeCR = '1010188164'; //'1010123429';
  smeDataList: SmeUser[] = [];
  getMockData = false;

  constructor(
    private smeService: SMEService, 
    private router: Router, 
    private sessionStorageService: SessionStorageService, 
    private readonly keycloak: KeycloakService, 
    private renderer: Renderer2, 
    private el: ElementRef
  ){}

  ngOnInit(){
    console.log('in SMEComponent');
    console.log('SMEComponent : this.keycloak.getToken() =>', this.keycloak.getToken());
  }

  searchSME(): void {
    
    this.sessionStorageService.setItem('CRNumber', this.smeCR);
    this.smeService.searchSmeByCR(this.smeCR, false).subscribe({
      next: (data) => {

        if(data){ // Sme founds and it is OK
          // Set a variable in sessionStorage
          this.sessionStorageService.setItem('smeUserId', data.id);
          this.sessionStorageService.setItem('smeId', data.sme?.id);
          console.log('Sme data : smeUserId =>', data.id);
          this.sessionStorageService.setItem('userId', data.userId);
          this.sessionStorageService.setItem('anonymousUserId', data.anonymousUserId);


          // Redirect to the 'sme' route with the SME's ID or any relevant information
          //this.router.navigate(['/sme', data.id]); // Adjust as per your data structure
          this.router.navigate(['1']); 
        } else { // Sme Not founds OR expired OR ..
          // Display the error message sent from the backend 
        }

        
      },
      error: (e) => console.error(e)
    });
  }

  

  ngOnDestroy(){}

}
