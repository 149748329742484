import { Injectable, OnInit } from '@angular/core';
import { Member } from '../models/member';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MembersFile } from '../models/member-file';
import { AppConfigService } from 'src/app/shared/config/app-config.service';


//const baseUrl = 'http://localhost:8080/api/sme/1/member';

@Injectable({
  providedIn: 'root'
})

export class MemberService {
  
  private apiEndpoints: any;
  
    constructor(private http: HttpClient, private configService: AppConfigService) { 
      this.apiEndpoints = configService.getApiEndpoints();
    }


    addMember(data: any): Observable<any> {
      const endpoint = this.apiEndpoints.sme.addMember.replace('${smeUserId}', data.smeUserId.toString()); //this.configService.getApiUrl('addMember');
      const url = this.configService.getEndpointUrl(endpoint);

      return this.http.post(url, data);
    }

    editMember(data: any): Observable<any>{
      const endpoint = this.apiEndpoints.sme.editMember.replace('${smeUserId}', data.smeUserId.toString()).replace('${memberId}', data.id); //this.configService.getApiUrl('addMember');
      const url = this.configService.getEndpointUrl(endpoint);
      console.log('editMember : ', url);
      return this.http.put(url, data);
    }

    deleteMember(data: any): Observable<any>{
      const endpoint = this.apiEndpoints.sme.deleteMember.replace('${smeUserId}', data.smeUserId.toString()).replace('${memberId}', data.id); //this.configService.getApiUrl('addMember');
      const url = this.configService.getEndpointUrl(endpoint);

      return this.http.delete(url, data);
    }

    addMemberByExcel(smeUserId: any, formData: FormData): Observable<any> {
      const endpoint = this.apiEndpoints.sme.addMembersByExcel.replace('${smeUserId}', smeUserId.toString()); //this.configService.getApiUrl('addMember');
      const url = this.configService.getEndpointUrl(endpoint);
      
      // Get the file from FormData
      const file: File | null = formData.get('membersFile') as File;

      // Check if a file was retrieved and validate its properties
      if (!file) {
        return throwError('Invalid file');
      }

      // Check file type (e.g., verify it's an Excel file)
      const allowedFileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      if (!allowedFileTypes.includes(file.type)) {
        return throwError('Invalid file type. Please upload an Excel file.');
      }

      // Check file size (if needed)
      const maxFileSizeMB = 10; // Set your maximum file size in MB
      const fileSizeMB = file.size / (1024 * 1024); // Convert file size to MB
      if (fileSizeMB > maxFileSizeMB) {
        return throwError(`File size exceeds ${maxFileSizeMB}MB limit.`);
      }

      console.log('formData =>',formData);
      return this.http.post(url, formData);
  }

    findMemberBySmeUserId(smeUserId: string): Observable<any> {
      const endpoint = this.apiEndpoints.sme.getMembers.replace('${smeUserId}', smeUserId.toString()); //this.configService.getApiUrl('addMember');
      const url = this.configService.getEndpointUrl(endpoint);

      return this.http.get(url);
    }

}