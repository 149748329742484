import { Injectable, OnDestroy } from '@angular/core';
import { Order } from '../models/order';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { AppConfigService } from 'src/app/shared/config/app-config.service';


@Injectable({
  providedIn: 'root'
})

export class OrderService {

    private sseStream!: Subscription;
    private apiEndpoints: any;

    constructor(private http: HttpClient, private configService: AppConfigService) { 
        this.apiEndpoints = configService.getApiEndpoints();
    }

    getOrderSummary(data: any): Observable<any>{
        const endpoint = this.apiEndpoints.orders.getOrderSummary;
        const url = this.configService.getEndpointUrl(endpoint);

        return this.http.post(url, data);
    }

    createOrder(data: any): Promise<any>{
        const endpoint = this.apiEndpoints.orders.createOrder;
        const url = this.configService.getEndpointUrl(endpoint);

        return this.http.post(url, data).toPromise();
    }

    getPaymentBrand(): Observable<any>{
        const endpoint = this.apiEndpoints.orders.getPaymentBrand;
        const url = this.configService.getEndpointUrl(endpoint);

        return this.http.get(url);
    }
    
}