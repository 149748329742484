<div id="disclosures" dir="rtl" class=" shadow border-1">
    <div class="disclosure-container">

   
    <h5 class="box-title mb-4 mt-2" style="padding-right: 12px;">
        <span id="header-label">الافصاحات الطبية</span>
    </h5>
    <!-- Disclosures -->
    <div *ngFor="let disclosure of disclosure; let i = index" class="disclosure-group" >
        <table class="table">
            <tbody>
            <tr >
                <td width="750" rowspan="2" class="shadow border-1 rounded" style="border: none;">

                    
                <!-- Disclosures title -->
                <table class="table">
                    <tr><td><div class="disc-title" style="border:none">{{ disclosure.title }}</div></td></tr>
                </table>
                    
                    <!-- Sub Disclosures -->
                    <table *ngIf="disclosure.subDisclosures.length > 0"  >
                        <tbody>
                            <tr *ngFor="let subDisclosure of disclosure.subDisclosures; let j = index" >
                                
                                <!-- sub RADIO -->
                                <td *ngIf="subDisclosure.hasInput=='MULTI_CHOICES_RADIO'" style="white-space:nowrap" >
                                    <div class="form-check">
                                        <input type="radio" 
                                        [id]="subDisclosure.id"
                                        [name]="(memberId + '_' + subDisclosure.parentDisclosureId)" 
                                        [checked]="isDisclosureSelected(subDisclosure.id)" 
                                        (change)="onDisclosureInputChange(subDisclosure.id, subDisclosure.hasInput, $event)"
                                        [value]="1" 
                                        class="form-check-input" />
                                        <label class="form-check-label" for="{{subDisclosure.id}}">{{ subDisclosure.title }}</label>
                                    </div>
                                </td>
                                
                                <!-- sub TEXT -->  
                                <td *ngIf="subDisclosure.hasInput=='TEXT' && subDisclosure.code!='CHILDBIRTH_DATE'" >
                                    
                                    <div class="form-check" >
                                        <!-- <label  for="{{subDisclosure.id}}"><span>{{ subDisclosure.title }}</span></label> -->
                                        <input type="text"  [id]="subDisclosure.id"  class="form-control ps-5"
                                        [value]="disclosureValue(subDisclosure.id)" placeholder="{{ subDisclosure.title }}" 
                                        (change)="onDisclosureInputChange(subDisclosure.id, subDisclosure.hasInput, $event)"
                                         />
                                    </div>
                                    
                                </td>

                                 <td *ngIf="subDisclosure.hasInput=='TEXT' && subDisclosure.code=='CHILDBIRTH_DATE'" >
                                    <label>اختر تاريخ بداية الوثيقة</label> 
                                    <div class="form-icon">
                                      <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                                      <input name="date" type="text"
                                      [id]="subDisclosure.id" 
                                       class="form-control ps-5"
                                       style="padding-right: 40px;"
                                       [value]="disclosureValue(subDisclosure.id)" placeholder="{{ subDisclosure.title }}" 
                                       (change)="onDisclosureInputChange(subDisclosure.id, subDisclosure.hasInput, $event)"
                                      [matDatepicker]="datepicker"
                                        placeholder="تاريخ بداية الوثيقة" >
                                    </div>
                                    
                                    
                                          <mat-datepicker #datepicker class="datepicker">
                                            <mat-datepicker-actions>
                                              <button class="btn btn-danger btn-sm" matDatepickerCancel >ألغاء</button>
                                              <button class="btn btn-soft-info btn-sm" matDatepickerApply style="margin-right: 8px;">مصادقة</button>
                                            </mat-datepicker-actions>
                                          </mat-datepicker>

                                 
                                     
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                </td> 

                <!-- RADIO -->  
                <td *ngIf="disclosure.hasInput=='YESNO_RADIO'" width="150" style="border: none;">
                    <div class="form-check">
                        <input type="radio" class="form-check-input custom-radio" 
                        [id]="(memberId + '_' + disclosure.id) + '_yes'" 
                        [checked]="isDisclosureSelected(disclosure.id)"
                        (change)="onDisclosureInputChange(disclosure.id, disclosure.hasInput, $event)"
                        [name]="(memberId + '_' + disclosure.id)"
                        [value]="1" />
                        <label  for="{{disclosure.id}}">نعم</label>
                    </div>
                    <div class="form-check">
                        <input type="radio" class="form-check-input custom-radio" 
                        [id]="(memberId + '_' + disclosure.id) + '_no'" 
                        (change)="onDisclosureInputChange(disclosure.id, disclosure.hasInput, $event)"
                        [name]="(memberId + '_' + disclosure.id)"
                        [value]="0" />
                        <label class="form-check-label" for="{{disclosure.id}}">لا</label>
                    </div>
                </td>
                
                <!-- TEXT --> 
                <td *ngIf="disclosure.hasInput=='TEXT'">
                    <div class="form-check">
                        <label  for="{{disclosure.id}}"><span>{{ disclosure.title }}</span></label>
                        <input  type="text" 
                        [id]="(memberId + '_' + disclosure.id) + '_' + disclosure.id"
                        [value]="disclosureValue(disclosure.id)"
                        (change)="onDisclosureInputChange(disclosure.id, disclosure.hasInput, $event)"
                        />
                    </div>
                </td> 
                <td *ngIf="disclosure.hasInput=='TEXT' && disclosure.code== 'CHILDBIRTH_DATE'" style="background-color: red;">
                    <div >
                        <label  for="{{disclosure.id}}"><span>{{ disclosure.title }} </span></label>
                        <input  type="text" 
                        placeholder="adzdzda"
                        [id]="(memberId + '_' + disclosure.id) + '_' + disclosure.id"
                        [value]="disclosureValue(disclosure.id)"
                        (change)="onDisclosureInputChange(disclosure.id, disclosure.hasInput, $event)"
                        />
                    </div>
                </td> 
                
            </tr>
            <tr>
                <td colspan="2" >
                    <button *ngIf="disclosure.hasReportBtn" class="btn add-disclosure-btn">
                        إرفاق التقرير
                    </button>
                </td>
            </tr> 
            </tbody>
        </table>
        <button class="btn btn-save"  (click)="addMemberDisclosures()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy-fill" viewBox="0 0 16 16">
                <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z"/>
                <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z"/>
              </svg>
            <span (click)="addMemberDisclosures()">حفظ البيانات

            </span>
        </button>
        <!-- <mat-icon style="color:#e18989;line-height:1.3 ;" (click)="addMemberDisclosures()" style="padding-right: 20px;">save</mat-icon>         -->
        </div>
    </div>
</div>
