import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PubSubService {
  private refreshSubject = new Subject<void>();
  private loginSuccessSubject = new Subject<void>();
  private selectQuotationActionSubject = new Subject<number>();
  private acceptOrderActionSubject = new Subject<boolean>();

  refresh$ = this.refreshSubject.asObservable();
  loginSuccess$ = this.loginSuccessSubject.asObservable();
  selectQuotationAction$ = this.selectQuotationActionSubject.asObservable();
  acceptOrderAction$ = this.acceptOrderActionSubject.asObservable();

  triggerRefresh() {
    this.refreshSubject.next();
  }

  emitLoginSuccess() {
    this.loginSuccessSubject.next();
  }

  selectQuotationAction(quotationId: number){
    this.selectQuotationActionSubject.next(quotationId);
  }

  acceptOrderAction(isChecked: boolean){
    this.acceptOrderActionSubject.next(isChecked);
  }
}
