import { ChangeDetectorRef, Component, ComponentRef, Injector, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberDataComponent } from 'src/app/member/components/member-data/member-data.component';
import { SmeDataComponent } from 'src/app/sme/sme-data/sme-data.component';
import { SessionStorageService } from '../../services/session-storage.service';
import { QuotationComponent } from 'src/app/quotation/components/quotation.component';
import { OrderComponent } from 'src/app/payment/components/order/order.component';
import { PaymentComponent } from 'src/app/payment/payment.component';
import { InsurancePolicyComponent } from 'src/app/insurance-policy/insurance-policy.component';
import { PubSubService } from '../../services/pub-sub.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-step-tab',
  templateUrl: './step-tab.component.html',
  styleUrls: ['./step-tab.component.css']
})
export class StepTabComponent implements OnInit {

  stepId!: number;
  progressBarWidth: string = 'width:25%';

  parentTabs!: any[];
  btnNextTargetStepId!: string;
  btnNextTargetStepTitle!: string;
  hideBtnNext: boolean = false;
  btnNextLoading: boolean = false;

  // First route after exit from stepTab
  exitStepId = 'ins-policy';
  exitStepTitle = 'اصدار الوثيقة';

  tabs: any[] = [
    //{ index: 0, isCurrent: true, stepId: 1, title: 'تفاصيل الشركة', component: SmeDataComponent },
    { isCurrent: false, stepId: 1, title: 'معلومات المؤمن لهم', component: MemberDataComponent },
    { isCurrent: false, stepId: 2, title: 'قائمة الأسعار', component: QuotationComponent, hideBtnNext: true },
    { isCurrent: false, stepId: 3, title: 'الدفع', component: OrderComponent, hideBtnNext: true},
    { isCurrent: false, stepId: 4, title: 'المتابعة إلى الدفع', component: PaymentComponent, parentStepId: 3, hideBtnNext: true }
    // Add more tab data as needed...
  ];

  // The <ng-container #componentContainer></ng-container> element. Inside we will inject the desired component
  loadedComponent: ComponentRef<any>[] = [];
  private selectQuotationActionSubscription!: Subscription;
  private acceptOrderActionSubscription!: Subscription;

  @ViewChild('componentContainer', { read: ViewContainerRef }) container!: ViewContainerRef;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private cdr: ChangeDetectorRef,
    private pubSubService: PubSubService,
    private spinnerService: NgxSpinnerService
  ) {}


  ngOnInit(): void {
    // Access route data to get hideFooter value
    this.stepId = this.route.snapshot.data['stepId'];
  }

  // Ensure that the container is available in ngAfterViewInit
  ngAfterViewInit() {
    if (this.container) {
      this.loadComponent(); // Call the loadComponent after verifying container existence
      this.initTabElements();
      this.cdr.detectChanges(); // Manually trigger change detection
    }

  }

  loadComponent() {
    const targetTab = this.tabs.find(d => (d.stepId === this.stepId));
    const componentType = targetTab.component;
  
    if (componentType) {
      const componentRef = this.container.createComponent(componentType);
      this.loadedComponent.push(componentRef);
    }
    
  }
  currentProgressPerCent:number = 0;
  initTabElements(){
    

    // Set the tabs to display
    //this.parentTabs = this.tabs.filter(tab => !tab.parentStepId);
    this.parentTabs = this.tabs;
    
    // Set the progressBar Width
    const widthUnit = 100/this.parentTabs.length;
    this.progressBarWidth = 'width:' + (this.stepId*widthUnit) + '%';

    // Set btn next 
    if(this.tabs[this.stepId] !== undefined){
      this.btnNextTargetStepId = (this.stepId+1).toString();
      this.btnNextTargetStepTitle = this.tabs[this.stepId].title;
    } else {
      this.btnNextTargetStepId = this.exitStepId;
      this.btnNextTargetStepTitle = this.exitStepTitle;
    }

    // Display the btn next ?
    this.hideBtnNext = (this.tabs[this.stepId-1].hideBtnNext !== undefined) ? this.tabs[this.stepId-1].hideBtnNext : false;
    
    // Listen to selectQuotationAction coming from child component (Quotation component)
    this.selectQuotationActionSubscription = this.pubSubService.selectQuotationAction$.subscribe((quotationId) => {
      const orderStepTab = this.tabs.find((tab) => tab.component == OrderComponent);
      this.passToStep(orderStepTab.stepId, quotationId);
    })

    // Listen to acceptOrderAction coming from child component (Order component)
    this.acceptOrderActionSubscription = this.pubSubService.acceptOrderAction$.subscribe((isChecked: boolean) => {
      this.hideBtnNext = !isChecked;
    })
  }

  async passToStep(stepId: string, keyParameter?: string | number): Promise<void>{

    // Set loading to true to show the loader
    this.spinnerService.show();

    //keyParameter: Could comes form 2 sources :
    //1. Pub/Sub event (selectQuotationAction in QuotationComponent) => Sent in passToStep(keyParameter)
    //2. Next in OrderComponent => Sent in saveAllOnNextAction() { return data{'keyParameter':id}}

    // Pre-action before routing
    for (const componentRef of this.loadedComponent) {
      // Invoke methods or perform actions on each component
      const data = await componentRef.instance.saveAllOnNextAction();

      // data could contains the keyParameter in case of the order step
      if(keyParameter == undefined && data != undefined && data.keyParameter !== undefined){
        keyParameter = data.keyParameter;
      }
    };

    const routeArray = keyParameter ? [stepId, keyParameter] : [stepId];
    this.spinnerService.hide();
    this.router.navigate(routeArray); 
  }

  /*showSpinner() {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 5000);
  }*/
  


}
