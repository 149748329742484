

        <div  *ngIf="showInvoiceDetails===true"  class="main-content" style="padding:50px;margin-top: 100px">
            <section role="tabpanel" class="body" aria-hidden="false">
                <div class="stepform-section fade-in">
                    <div  class="centerpaddingbox">

                        <div id="ErrorMessage" class="alert alert-danger" style="display:none"></div>

                        <div *ngIf="paymentStatus?.status=='SUCCESSFUL'" class="card-header p-lg-5 p-4 rounded border border-0 shadow collapsed bg-success">
                            <h3 style="color:white">تمت عملية الدفع بنجاح !</h3>
                            <div style="color:white">
                            شكرا لشرائك وثيقة تأمين عبر مسارات، يرجى مراعات بأن اصدار الوثيقة و رفع معلوماتها الى مجلس الضمان الصحي التعاوني قد يستغرق من 24 الى 48 ساعة
                            </div>
                        </div>

                        <div *ngIf="paymentStatus?.status=='FAILED'" class="card-header p-lg-5 p-4  rounded border border-0 shadow collapsed bg-danger">
                            <h3 style="color:white">لم تنجح عملية الدفع </h3>
                            <div style="color:white">
                                سيقوم فريقنا بالتواصل معكم                             
                            </div>
                        </div>

                        <div *ngIf="paymentStatus?.status=='PENDING'" class="card-header p-4 rounded bg-primary">
                            <h3 style="color:white">تم طلب الشراء بنجاح</h3>
                            <div style="color:white">
                                سنقوم باعلامكم حال تاكيد عملية الدفع                             
                            </div>
                        </div>

                        <div *ngIf="paymentStatus?.status!='FAILED'" class="form-section">
                            
                                <div class="row mt-3">

                                    <div class="col-md-8">
                                    <!-- policy Details box -->
                                    <div class="policy-header" >
                                        <span class="header-content">
                                          <i class="mdi mdi-file"></i>
                                          <span class="header-content-title">
                                            تفاصيل الطلب
                                          </span>
                                          </span>
                                      </div>
                                      <div class="policy-body border border-0 bg-half-260 p-lg-5 p-4"
                                      style="background-image: url('assets/images/bg-images/marketing-shape.png');">
                                      <div class="row" style="font-size:85%" >
                                        <div class="col-lg-3 col-md-6 col-12 mt-5">
                                            <div class="features feature-primary text-center">
                                                <div class="image position-relative d-inline-block">
                                                  <i class="uil uil-building h1 text-primary"></i>
                                                </div>
                    
                                                <div class="content mt-4">
                                                    <h5 class="h6"> {{smeCrName}}</h5>
                                                    <p  class="text-muted mb-0">رقم السجل التجاري : {{smeCrNumber}}</p>
                                                    <p  class="text-muted mb-0">رقم الكفيل : {{smeCrEntityNumber}}</p>
                                                </div>
                                            </div>
                                        </div><!--end col-->
                                        
                                        <div class="col-lg-3 col-md-6 col-12 mt-5">
                                            <div class="features feature-primary text-center">
                                                <div class="image position-relative d-inline-block">
                                                  <i class="uil uil-pricetag-alt h1 text-primary"></i>
                                                </div>
                    
                                                <div class="content mt-4">
                                                    <h5 class="h6">            
                                                      رقم التسعيرة : {{quotationId}}
                                                    </h5>
                                               
                                                    <p class="text-muted mb-0">تاريخ بداية التأمين : {{newPolicyStartDate}}</p>
                                                    <p class="text-muted mb-0">تاريخ الانتهاء : -</p>
                                                </div>
                                            </div>
                                        </div><!--end col-->
                                        
                                        <div class="col-lg-3 col-md-6 col-12 mt-5">
                                          <div class="features feature-primary text-center">
                                              <div class="image position-relative d-inline-block">
                                                <i class="uil uil-users-alt h1 text-primary"></i>
                                              </div>
                    
                                              <div class="content mt-4">
                                                  <h5 class="h6">            
                                                      عدد المؤمن لهم : {{totalMemberCount}}
                                                  </h5>
                                             
                                                  <p class="text-muted mb-0">موظفين : {{sponsorsMemberCount}}</p>
                                                  <p class="text-muted mb-0">تابعين : {{dependentsMemberCount}}</p>
                                              </div>
                                          </div>
                                      </div><!--end col-->
                    
                                        <div class="col-lg-3 col-md-6 col-12 mt-5">
                                            <div class="features feature-primary text-center">
                                                <div class="image position-relative d-inline-block">
                                                  <i class="uil uil uil-award-alt h1 text-primary"></i>
                                                </div>
                                                
                                                <div class="content mt-4">
                                                    <h5 class="h6"> الفئات مختارة : {{insClassCount}}</h5>
                                                    <p class="text-muted mb-0"></p>
                                                    <span class="tagcloud text-center mt-4" *ngFor="let entry of insClassData | keyvalue" [class.box]="true" >
                                                        <a href="jvascript:void(0)" class="rounded" [class.bg-soft-success]="entry.key == 'VIP'" [class.bg-soft-footer]="entry.key !== 'VIP'" >{{ entry.key }} : {{ entry.value }}</a>
                                                    </span>
                                                </div>
                                                
                                            </div>
                                        </div><!--end col-->
                                      
                                        
                                        <div class="mt-3">
                                            <h6>معلومات المنتج</h6>
                                            <ul class="small" style="list-style-type: none;">
                                              <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                                <defs>
                                                </defs>
                                                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                                    <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                                </g>
                                                </svg>
                                                عرض جميع الفئات
                                            </li>
                                              <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                                <defs>
                                                </defs>
                                                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                                    <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                                </g>
                                                </svg>
                        
                                                تقديم الاستعاضة الطبية عبر الخدمات الالكترونية</li>
                                              <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                                <defs>
                                                </defs>
                                                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                                    <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                                </g>
                                                </svg>
                                                خدمة المساعدة الدولية للفئة (في في أي بي / فئة أي)</li>
                                              <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                                <defs>
                                                </defs>
                                                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                                    <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                                </g>
                                                </svg>
                                                خدمة توصيل الدواء</li>
                                              <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                                <defs>
                                                </defs>
                                                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                                    <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                                </g>
                                                </svg>
                                                الراي الطبي الاخر</li>
                                              <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                                <defs>
                                                </defs>
                                                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                                    <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                                </g>
                                                </svg>
                                                الموافقات المباشرة الالكترونية</li>
                                              <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                                                <defs>
                                                </defs>
                                                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                                    <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                                </g>
                                                </svg>
                                                اختيار الشبكة الطبية</li>
                                            </ul>
                                            
                                          </div>
                                       
                                      
                                      </div>
                                      
                                    </div>
                                      
                                    
                                    </div>

                                    <div class="col col-lg-4 col-sm-12 fade-in">
                                        <div class="fixed-container sticky-bar small">
                                          
                                            <div class="policy-header" >
                                                <span class="header-content">
                                                  <i class="mdi mdi-invoice"></i>
                                                  <span class="header-content-title">
                                                    تفاصيل الدفع
                                                  </span>
                                                  </span>
                                              </div>
                                              
                                              
                              
                                        <main class="ticket-system">
                                          <div class="top">
                                          <div class="printer"></div>
                                          </div>
                                          <div class="receipts-wrapper">
                                             <div class="receipts">
                                                <div class="receipt">
                                                  
                                                  <div class="card p-3 rounded border-0  overflow-hidden">
                                                    <div class="row align-items-center g-0">
                                                        
                                                      
                                                      
                                                      <div class="row">
                                                        <div class="col col-lg-12 col-md-12 order-1 order-md-2 mt-4 text-center">
                                                          <span class="mb-0 text-nowrap">
                                                            <h5>
                                                              <a href="">
                                                                <span id="ICCompanyName" style="color:#026a7c">تم الدفع لفائدة {{insCompanyName}} </span>
                                                              </a>
                                                            </h5>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      
                                                      <div class="col col-lg-7 col-md-12 order-2 order-md-1">
                                                          <div class="card-body content">
                                                              <!--<h5 class="text-nowrap"><a href="javascript:void(0)" class="card-title title text-dark">
                                                                  <span id="ICCompanyName" style="color:#026a7c">{{insCompanyName}}</span></a></h5>-->
                                                              <p ></p>
                                                              <p class="mb-0" >
                                                                <i class="uil uil-check-square text-primary me-1"></i>عدد المؤمن لهم : {{totalMemberCount}}
                                                              </p>
                                                              <p class="mb-2" >
                                                                <i class="uil uil-check-square text-primary me-1"></i>الفئات مختارة : {{insClassCount}}
                                                              </p>
                                                              
                                                              
                                                          </div>
                                                      </div>
                                                      
                                                      <div class="col col-lg-5 col-md-12 order-1 order-md-2">
                                                          <img src="/assets/images/masarat/quotation/ins-company-logos/{{quotationInsCompanyCode}}.png" class="img-fluid" alt="logo-insurance-company">
                                                      </div>
                                                      
                                                      <div class="row">
                                                        <div class="col col-lg-12 col-md-12 order-1 order-md-2 text-center">
                                                          <span class="mb-0 text-nowrap">
                                                            <small>وثائق الشركات الصغيرة و المتوسطة</small>
                                                          </span>
                                                        </div>
                                                      </div>
                                                        
                                                    </div> 
                                                </div>
                                                  
                                                  <div class="card rounded border-top border-1  overflow-hidden">
                                                    <div class="row align-items-center g-0">
                                                        <div class="col col-lg-12 col-md-12 order-2 order-md-1">
                                                            <div class="card-body content">
                                                              <div class="row">
                                                                <div class="col col-lg-12 col-md-12 order-1 order-md-2 text-center">
                                                                  <span class="mb-0 text-nowrap">
                                                                    <h5>
                                                                      <span id="ICCompanyName" style="color:#026a7c">ملخص الدفع</span>
                                                                    </h5>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                                <div class="ticket-body">
                                                                  <div class="ticket-section">
                                                                      <span>
                                                                        المجموع (بدون ضريبة):
                                            
                                                                        </span>
                                                                      <span class="text-nowrap">
                                                                        {{quotationPriceBeforeVat | number:'1.2-2'}} ر.س
                                                                      </span>
                                                                  </div>
                                                                  <div class="ticket-section">
                                                                      <span>
                                                                        +ضريبة القيمة المضافة (15.00%):
                                                                      </span>
                                                                      <span class="text-nowrap">
                                                                        {{quotationVatAmount | number:'1.2-2'}} ر.س
                                                                      </span>
                                                                  </div>
                                                  
                                                                  
                                                                </div>
                                                                <div class="ticket-body border-dashed border-top">
                                                                  <div class="ticket-section">
                                                                    <span class="h5">المبلغ الإجمالي</span>
                                                                    <span class="text-primary fw-bold h5 text-nowrap">{{quotationPrice | number:'1.2-2'}} ر.س</span>
                                                                    
                                                                  </div>
                                                                  <span class="text-muted mb-0 text-nowrap float-end">
                                                                    <small>شامل جميع الضرائب و الرسوم</small>                                  
                                                                  </span>
                                                                  
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div> 
                                                  </div>
                                                   
                                                </div>
                                                <div class="receipt qr-code">
                                                    <div class="row align-items-center">
                                                        <div class="col-3">
                                                            <svg class="qr" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.938 29.938">
                                                                <path d="M7.129 15.683h1.427v1.427h1.426v1.426H2.853V17.11h1.426v-2.853h2.853v1.426h-.003zm18.535 12.83h1.424v-1.426h-1.424v1.426zM8.555 15.683h1.426v-1.426H8.555v1.426zm19.957 12.83h1.427v-1.426h-1.427v1.426zm-17.104 1.425h2.85v-1.426h-2.85v1.426zm12.829 0v-1.426H22.81v1.426h1.427zm-5.702 0h1.426v-2.852h-1.426v2.852zM7.129 11.406v1.426h4.277v-1.426H7.129zm-1.424 1.425v-1.426H2.852v2.852h1.426v-1.426h1.427zm4.276-2.852H.002V.001h9.979v9.978zM8.555 1.427H1.426v7.127h7.129V1.427zm-5.703 25.66h4.276V22.81H2.852v4.277zm14.256-1.427v1.427h1.428V25.66h-1.428zM7.129 2.853H2.853v4.275h4.276V2.853zM29.938.001V9.98h-9.979V.001h9.979zm-1.426 1.426h-7.127v7.127h7.127V1.427zM0 19.957h9.98v9.979H0v-9.979zm1.427 8.556h7.129v-7.129H1.427v7.129zm0-17.107H0v7.129h1.427v-7.129zm18.532 7.127v1.424h1.426v-1.424h-1.426zm-4.277 5.703V22.81h-1.425v1.427h-2.85v2.853h2.85v1.426h1.425v-2.853h1.427v-1.426h-1.427v-.001zM11.408 5.704h2.85V4.276h-2.85v1.428zm11.403 11.405h2.854v1.426h1.425v-4.276h-1.425v-2.853h-1.428v4.277h-4.274v1.427h1.426v1.426h1.426V17.11h-.004zm1.426 4.275H22.81v-1.427h-1.426v2.853h-4.276v1.427h2.854v2.853h1.426v1.426h1.426v-2.853h5.701v-1.426h-4.276v-2.853h-.002zm0 0h1.428v-2.851h-1.428v2.851zm-11.405 0v-1.427h1.424v-1.424h1.425v-1.426h1.427v-2.853h4.276v-2.853h-1.426v1.426h-1.426V7.125h-1.426V4.272h1.426V0h-1.426v2.852H15.68V0h-4.276v2.852h1.426V1.426h1.424v2.85h1.426v4.277h1.426v1.426H15.68v2.852h-1.426V9.979H12.83V8.554h-1.426v2.852h1.426v1.426h-1.426v4.278h1.426v-2.853h1.424v2.853H12.83v1.426h-1.426v4.274h2.85v-1.426h-1.422zm15.68 1.426v-1.426h-2.85v1.426h2.85zM27.086 2.853h-4.275v4.275h4.275V2.853zM15.682 21.384h2.854v-1.427h-1.428v-1.424h-1.427v2.851zm2.853-2.851v-1.426h-1.428v1.426h1.428zm8.551-5.702h2.853v-1.426h-2.853v1.426zm1.426 11.405h1.427V22.81h-1.427v1.426zm0-8.553h1.427v-1.426h-1.427v1.426zm-12.83-7.129h-1.425V9.98h1.425V8.554z" fill="#fff"></path>
                                                             </svg>
                                                        </div>
                                                        <div class="col-7">
                                                            <div class="description ps-4 text-nowrap">
                                                                <h3>رقم العملية : {{transactionId}}</h3>
                                                                <p class="mb-0" >
                                                                     
                                                                </p>
                                                             </div>
                                                        </div>
                                                    </div>
                                                  
                                                  
                                               </div>
                                             </div>
                                          </div>
                                       </main>
                                      
                                    
                              
                                        <br>
                                        
                                        
                              
                                        </div>
                                      </div>
                                    
                                    
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <script type="text/javascript">
            var initiatediscountVal = '0';
        </script>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
