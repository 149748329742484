
<button color="primary" mat-stroked-button [matMenuTriggerFor]="columnMenu">
    <ng-content></ng-content>
  </button>
  
  <mat-menu #columnMenu="matMenu" class="border-1 shadow rounded ">
    <div >
      <div class="dragable-row"
           *ngFor="let column of columnInfo; let i = index"
           
          
          >
        <mat-icon >drag_indicator</mat-icon>
        
        <div class="form-check mb-0">
          <input class="form-check-input"
                 type="checkbox"
                 [checked]="!column.hidden"
                 (change)="toggleSelectedColumn(column.id)"
                 (click)="$event.stopPropagation();"
                 id="flexCheckDefault{{i}}">
          
        </div>
        <label class="form-check-label" for="flexCheckDefault{{i}}">
            {{ column.name }}
          </label>
        <mat-card class="va-mat-table-drag-preview" *cdkDragPreview>
          <mat-icon>drag_indicator</mat-icon>
          <div class="form-check">
            <input class="form-check-input"
                   type="checkbox"
                   [checked]="!column.hidden"
                   id="flexCheckPreview{{i}}">
            <label class="form-check-label" for="flexCheckPreview{{i}}">
              {{ column.name }}
            </label>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-menu>
  