import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-board-sidebar',
  templateUrl: './user-board-sidebar.component.html',
  styleUrls: ['./user-board-sidebar.component.css']
})
export class UserBoardSidebarComponent {
  @Input() sidebarSections!: any[];
  @Input() userInformations!: any;
  Object = Object;
  
  ngOnInit(): void {
    
    console.log('side barSection  ====>', this.sidebarSections);
    
  }

}
