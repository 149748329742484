import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Quotation } from '../models/quotation';
import { QuotationService } from '../services/quotation.service';
import { Observable, map, take, Subscription } from 'rxjs';

import { EventSourceService } from '../../shared/services/event-source.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { StepTabChildInterface } from 'src/app/shared/models/step-tab-child';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.css']
})


export class QuotationComponent implements StepTabChildInterface, OnInit, OnDestroy {
  
  quotationList: Quotation[] = [];
  loading = true;
  selectedQuotationGroupId!: number;

  constructor(private sseService: EventSourceService, 
    private quotationService: QuotationService, 
    private sessionStorageService: SessionStorageService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
    ) {}
  
  saveAllOnNextAction(): void {
    //save quotation data if needs
  }
  
  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.selectedQuotationGroupId = params['qgId'];
    });

    if(this.selectedQuotationGroupId != undefined && this.selectedQuotationGroupId > 0){
      this.getQuotationByQuotationGroup(this.selectedQuotationGroupId);
    } else {
      this.getLiveQuotation();
    }

    
    //this.quotationList = this.quotationService.getLiveQuotation(this.sessionStorageService.getItem('smeUserId'));
  }

  getLiveQuotation(): void {
    this.quotationService.getLiveQuotation(this.sessionStorageService.getItem('smeUserId')).subscribe(
      ({ quotations, isTerminated }) => {
        this.quotationList = quotations;
        if (isTerminated) {
          this.loading = false;
        }
      }
    );
  }

  getQuotationByQuotationGroup(quotationGroupId: number): void {
    this.quotationService.getQuotationByQuotationGroup(quotationGroupId).subscribe(
      (quotations) => {
        this.quotationList = quotations;
        this.loading = false; 
      }
    );
  }
  

  ngOnDestroy(): void {
    this.quotationService.sseStreamDestroy();
    this.quotationList = [];
  }
}
