import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiEndpoints } from './api-endpoints.interface';
import { endpoints } from './api-endpoints';


@Injectable({
  providedIn: 'root'
})

export class AppConfigService {
  private apiBaseUrl: string = environment.apiBaseUrl;
  private endpoints: ApiEndpoints = endpoints;
  

  getApiBaseUrl(): string {
    return `${this.apiBaseUrl}`;
  }

  getApiEndpoints(): ApiEndpoints {
    return this.endpoints;
  }

  getEndpointUrl(endpoints: String) {
    return `${this.apiBaseUrl}/${endpoints}`;
  }
}