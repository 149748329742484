import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; 
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { KeyValuePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuotationComponent } from './quotation/components/quotation.component';
import { QuotationBoxComponent } from './quotation/components/quotation-box/components/quotation-box.component';
import { SharedComponent } from './shared/components/shared.component';
import { SMEComponent } from './sme/components/sme.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { SmeDataComponent } from './sme/sme-data/sme-data.component';
import { MemberComponent } from './member/components/member.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MemberDataComponent } from './member/components/member-data/member-data.component';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MemberDisclosureComponent } from './member/components/member-disclosure/member-disclosure.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './shared/services/request-interceptor.service';
import { MemberFileComponent } from './member/components/member-file/member-file.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { StepTabComponent } from './shared/components/step-tab/step-tab.component';
import { Select2Module } from 'ng-select2-component';
import { MatIconModule } from '@angular/material/icon';
import { VaMatTableComponent } from './shared/components/va-mat-table/va-mat-table.component';
import { ColumnSorterComponent } from './shared/components/va-mat-table/actions/column-sorter/column-sorter.component';
import { MatMenuModule} from '@angular/material/menu';
import { DragDropModule} from '@angular/cdk/drag-drop';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import { MemberDataRowComponent } from './member/components/member-data-row/member-data-row.component';
import { CustomIntlPaginator } from './shared/components/paginator/CustomIntlPaginator';
import {MatDrawerMode, MatSidenavModule} from '@angular/material/sidenav';
import { UserBoardComponent } from './user-board/user-board.component';
import { UserBoardSidebarComponent } from './user-board/components/user-board-sidebar/user-board-sidebar.component';
import { QuotationGroupsComponent } from './user-board/components/quotation-groups/quotation-groups.component';
import { OrderComponent } from './payment/components/order/order.component';
import { PaymentComponent } from './payment/payment.component';
import { InsurancePolicyComponent } from './insurance-policy/insurance-policy.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { UserSettingsComponent } from './user-board/components/settings/user-settings/user-settings.component';
import { OtpVerificationComponent } from './shared/components/otp-verification/otp-verification.component';



function initializeKeycloak(keycloak: KeycloakService) {
  console.log('environment ==>', environment);
  return () =>
    keycloak.init({
      config: {
        realm: 'masarat',
        url: environment.iamHost,
        clientId: 'masarat-app'
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/keycloak-silent-check-sso.html'
          
      }
    });
}

@NgModule({
  declarations: [
    AppComponent,
    QuotationComponent,
    QuotationBoxComponent,
    SharedComponent,
    SMEComponent,
    HeaderComponent,
    SmeDataComponent, 
    MemberComponent, 
    MemberDataComponent, 
    MemberDisclosureComponent, MemberFileComponent, FooterComponent, StepTabComponent, 
    ColumnSorterComponent,
    VaMatTableComponent,
    MemberDataRowComponent,
    UserBoardComponent,
    UserBoardSidebarComponent,
    QuotationGroupsComponent,
    OrderComponent,
    PaymentComponent,
    InsurancePolicyComponent,
    UserSettingsComponent,
    OtpVerificationComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatTableModule, MatPaginatorModule,
    MatIconModule,
    MatSortModule,
    MatExpansionModule,
    KeycloakAngularModule,
    Select2Module,
    MatMenuModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatCheckboxModule,
    MatSidenavModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule
  ],
  /*providers: [
    DatePipe,
  ],*/

  providers: [
    DatePipe,
    KeyValuePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useValue: CustomIntlPaginator() }
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}