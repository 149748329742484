import { ApiEndpoints } from "./api-endpoints.interface";

export const endpoints : ApiEndpoints = {
    sme: {
        searchSmeByCR: 'sme/${smeCR}',
        getSmeUserById: 'sme/users/${smeUserId}',
        updateSmeUserById: 'sme/users/${smeUserId}',
        updateSmeUserBySmeIdAndUserId: 'sme/${smeId}/users/${userId}',
        addMember: 'sme/${smeUserId}/member',
        editMember: 'sme/${smeUserId}/members/${memberId}',
        deleteMember: 'sme/${smeUserId}/members/${memberId}',
        addMembersByExcel: 'sme/${smeUserId}/upload-members',
        getMembers: 'sme/${smeUserId}/members',
        getList: 'sme/common/list',
        
    },
    disclosure:{
        getDisclosures: 'disclosures',
    },
    member: {
        addMemberDisclosures: 'member/${memberId}/disclosure',
        getMemberDisclosures: 'member/${memberId}/disclosure',
        saveAllMemberDisclosures: 'member/disclosure',
    },
    quotation: {
        getLiveQuotation: 'quotations/live/smeUserId/${smeUserId}',
        getActiveQuotation: 'quot-groups',
        getCountActiveQuotation: 'quot-groups/cnt',
        getQuotationByQuotationGroup: 'quotations/quot-grps/${quotationGroupId}',
        getList: 'quotations/common/list',
    },
    orders: {
        getOrderSummary: 'orders/summary',
        createOrder: 'orders',
        getOrderByQuotationId: 'orders/quotations/${quotId}',
        getPaymentBrand: 'orders/payment-brands',
    },
    payments: {
        prepareCheckout: 'payments/checkout',
        getPaymentStatus: 'payments/checkouts/${checkoutId}/payment',
        getTransactionByCheckoutId: 'payments/transaction/checkout/${checkoutId}'
    },
    utility: {
        i18n: 'i18n/${locale}'
    },
    users: {
        getInfos: 'users/whoami',
        updateAccount: 'users/update-my-account',
        updatePassword: 'users/update-my-password',
        requestPhoneUpdate: 'users/request-phone-update',
        verifyPhoneUpdate: 'users/verify-phone-update',
        updateEmail: 'users/update-email'
    }
    // Add more endpoints as needed
  }