import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'va-mat-table',
  templateUrl: './va-mat-table.component.html',
  styleUrls: ['./va-mat-table.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class VaMatTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}