import { Component } from '@angular/core';
import { Member } from '../../models/member';
import { MemberService } from '../../services/member.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { PubSubService } from 'src/app/shared/services/pub-sub.service';
import { MembersFile } from '../../models/member-file';

@Component({
  selector: 'app-member-file',
  templateUrl: './member-file.component.html',
  styleUrls: ['./member-file.component.scss']
})
export class MemberFileComponent {

  smeUserId!: string;
  membersFile!: MembersFile;
  constructor(private memberService: MemberService, private sessionStorageService: SessionStorageService, private refreshService: PubSubService){}

  ngOnInit(){
    this.smeUserId = this.sessionStorageService.getItem('smeUserId');
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.uploadFile(file);
    }

    console.log('onFileSelected');
  }

  uploadFile(file: File) {
    // Implement your file upload logic here
    // You can send this file to the server using HTTP requests (e.g., HttpClient)
    // For instance:
    // const formData = new FormData();
    // formData.append('membersFile', file);
    // this.http.post('your API endpoint', formData).subscribe(response => {
    //   // Handle response
    // });

    this.membersFile = {
      'membersFile': file
    }

    const formData = new FormData();
    formData.append('membersFile', file); // Ensure the key matches the server's expected parameter name

    this.memberService.addMemberByExcel(this.smeUserId, formData).subscribe({
      next: (res) => {
        
        // Perform the action that requires a refresh
        // After the action is performed, trigger the refresh
        this.refreshService.triggerRefresh();
      },
      error: (e) => console.error(e)
    });
  }

}
