<div>
  <main>
    <section class="content">
      <app-header *ngIf="!hideHeader"></app-header>
      <div class="body-wrapper">
        <router-outlet></router-outlet>
      </div>
      <app-footer *ngIf="!hideFooter"></app-footer>
    </section>
  </main>
</div>