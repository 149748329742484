// shared/column-config.service.ts
import { Injectable } from '@angular/core';
import { EmployeeMarital } from 'src/assets/translation/employee-marital';
import { EmployeeRelation } from 'src/assets/translation/employee-relation';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { YakeenCheckStatus } from '../models/member';

@Injectable({
  providedIn: 'root'
})

export class MemberTableUtilityService {
    
    // Translation
    transRelation = EmployeeRelation;
    transMarital = EmployeeMarital;

    columnsToDisplayNames = [
        {
            key: "id",
            label: "#",
            width: "25%", // Here we can manage the column width in once place 
            infoElem: (element: any) => {
            return {
                tag: "img",
                class: "gender-img",
                value: `/assets/images/masarat/member/${this.getImgDir(element.nationality)}/${element.relation.toLowerCase()}-${element.gender.toLowerCase()}.png`
            };
            }
        },
        {
            key: "idNo",
            label: "الهوية",
            width: "100%", // Here we can manage the column width in once place 
            infoElem: (element: any) => {
            return {
                tag: "badge",
                class: "badge bg-soft-info",
                value: this.trans(element.name)
            };
            }
        },
        {
            key: "marital",
            label: "الحالة الاجتماعية",
            width: "60%",
            infoElem: (element: any) => {
            return {
                tag: "badge",
                class: "badge bg-soft-info",
                value: this.trans(element.relation)
            };
            }
        },
        {
            key: "birthDate",
            label: "العمر",
            width: "60%",
            infoElem: (element: any) => {
            return {
                tag: "badge",
                class: "badge bg-soft-info",
                value: this.trans(element.age) + " سنة"
            };
            }
        },
        {
            key: "showMemberDisclosure",
            label: '<i class="mdi mdi-heart-pulse h5"></i>', // الافصاحات الطبية,
            width: "45%",
            infoElem: (element: any) => {
            return {
                tag: "badge",
                class: "badge bg-soft-info",
                value: this.trans(element.disclosureStats?.YESCount || 0)
            };
            }
        },
        {
            key: "showDependents",
            label: '<i class="mdi mdi-account-multiple h5" title="التابعين"></i>', // التابعين,
            width: "45%",
            infoElem: (element: any) => {
            return {
                tag: "badge",
                class: "badge bg-soft-info",
                value: element.dependentsCount
            };
            }
        },
        {
            key: "sponsorIdNo",
            label: '<i class="mdi mdi-account-arrow-up h5" title="الكفيل"></i>', // الكفيل,
            width: "45%",
            infoElem: (element: any) => {
            return {};
            }
        },
        {
            key: "actions",
            label: '<i class="mdi mdi-cog h6"></i>', // التحكم 
            width: "45%",
            infoElem: (element: any) => {
            return {
                tag: "badge",
                class: (element.insClass !== 'VIP') ?  "badge bg-secondary insClass-badge" : "badge bg-soft-success insClass-badge",
                value: this.trans(element.insClass)
            };
            }
        }
    ];
  
    public YakeenCheckStatus = YakeenCheckStatus;
     
    getColumnsConfig(isDependent: boolean = false){
        if(isDependent){
            return this.columnsToDisplayNames.filter(element => element.key !== 'showDependents');
        } else {
            return this.columnsToDisplayNames.filter(element => element.key !== 'sponsorIdNo');
        }
        
    }

    getImgDir(nationality: string): string {
        // Implement this method according to your logic
        return nationality.toLowerCase() === 'saudi' ? 'saudi' : 'nonsaudi';
    }

    trans(memberAttribute: string): any{
        const relation = EmployeeRelation[memberAttribute];
        const marital = EmployeeMarital[memberAttribute];
    
        if(relation != undefined){
          return relation;
        } else if(marital != undefined){
          return marital;
        } else {
          return memberAttribute;
        }
        
    }


    getDetailExpandAnimation() {
        return trigger('detailExpand', [
          state('collapsed', style({ height: '0px', minHeight: '0' })),
          state('expanded', style({ height: '*' })),
          transition('expanded <=> collapsed', animate('733ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]);
      }
    
    getDetailExpandMemberAnimation() {
    return trigger('detailExpandMember', [
        state('collapsed', style({ height: '0px', minHeight: '0', transform: 'translateX(100%)' })),
        state('expanded', style({ height: '*', transform: 'translateX(0)' })),
        transition('expanded <=> collapsed', animate('733ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]);
    }

    getVisibilityToggleAnimation() {
    return trigger('visibilityToggle', [
        state('hidden', style({ visibility: 'hidden', opacity: 0 })),
        state('visible', style({ visibility: 'visible', opacity: 1 })),
        transition('hidden => visible', animate('250ms 333ms ease-in')),
        transition('visible => hidden', animate('150ms ease-out')),
    ]);
    }


    setDisclosureStats(event: any, dataSource: MatTableDataSource<any>) {
        dataSource.data.forEach((member:any) => {
          if(member.id == event.memberId){
            member.disclosureStats = event.stats
          }
        });

        return dataSource;
    }

    isValidMemberStatus(){
        return [
            null,
            YakeenCheckStatus.DRAFT,
            YakeenCheckStatus.VALIDATED,
        ]
    }

}
