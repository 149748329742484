import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
import { ActivatedRoute, Router } from '@angular/router';

import { CommonService } from 'src/app/shared/services/common.service';
import { UserUpdateAccount } from 'src/app/user-board/models/user-update-account';
import { UserInfos } from 'src/app/user-board/models/user-infos';
import { UserUpdatePassword } from 'src/app/user-board/models/user-update-password';
import { UserUpdatePhoneNumber } from 'src/app/user-board/models/user-update-phoneNumber';
import { UserUpdateEmail } from 'src/app/user-board/models/user-update-email';
import { UserService } from 'src/app/user-board/services/user.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent {

  tab!: string;
  userInfos!: UserInfos;
  userUpdateAccount: UserUpdateAccount = {
    firstName: null,
    lastName: null,
    email: null,
    preferredLanguage: null
  };

  userUpdatePassword: UserUpdatePassword = {
    password: null,
    confirmPassword: null
  };

  userUpdatePhoneNumber: UserUpdatePhoneNumber = {
    phoneNumber: null
  }

  userUpdateEmail: UserUpdateEmail = {
    email: null,
    confirmEmail: null
  }

  firstName!: String;
  lastName!: String;
  preferredLanguage!: String;
  email!: String;
  phoneNumber!: String;
  //Transform the parentMemberDataList to select2 dropdown type
  langSelect2: Select2Data = [
      { value: 'ar', label: 'العربية' },
      { value: 'en', label: 'الانجليزية' }
  ];

  requireOtp: boolean = false;
  otp!: string;

  constructor(
    private readonly keycloak: KeycloakService,
   private userService : UserService,
    private sessionStorageService: SessionStorageService,
    private route: ActivatedRoute, 
    private router: Router,
    private commonService: CommonService
    ) {
    
  }

  ngOnInit(): void {
    this.tab = this.route.snapshot.data['tab'];
    this.getUserInfos();

  }

  initData(): void {
    this.firstName = this.userInfos?.firstName;
    this.lastName = this.userInfos?.lastName;
    this.preferredLanguage = this.userInfos?.preferredLanguage;
    this.email = this.userInfos?.email;
    this.phoneNumber = this.userInfos?.phoneNumber;
    
    // user update account model
    this.userUpdateAccount = {
      firstName: this.userInfos?.firstName,
      lastName: this.userInfos?.lastName,
      email: this.userInfos?.email,
      preferredLanguage: this.userInfos?.preferredLanguage
    }
    
  }


  getUserInfos(){

    this.userService.getUserInfos().subscribe({
      next: (data) => {
        
        // Emit the updated data once received
        this.userInfos = data;
        this.initData();
      },
      error: (e) => console.error(e)
    });
    
  }

  updateUserInfos(){

    this.userService.updateUserInfos(this.userUpdateAccount).subscribe({
      next: (data) => {
        
        // Emit the updated data once received
        
      },
      error: (e) => console.error(e)
    });
    
  }

  updateUserPassword(){
    
    this.userService.updateUserPassword(this.userUpdatePassword).subscribe({
      next: (data) => {
        
        // Emit the updated data once received
        
      },
      error: (e) => console.error(e)
    });
  }

  requestPhoneUpdate(): void{
      this.requireOtp = true;
      this.userService.requestPhoneUpdate(this.userUpdatePhoneNumber).subscribe({
        next: (data) => {
          
          // Emit the updated data once received
          
        },
        error: (e) => console.error(e)
      });
    
  }

  verifyPhoneUpdate(otp: string): void{
    this.userService.verifyPhoneUpdate(otp).subscribe({
      next: (data) => {
        this.requireOtp = false;
        // Emit the updated data once received
        
      },
      error: (e) => console.error(e)
    });
  }
  

  updateUserEmail(){
    this.userService.updateUserEmail(this.userUpdateEmail).subscribe({
      next: (data) => {
        
        // Emit the updated data once received
        
      },
      error: (e) => console.error(e)
    });
  }

  updatePreferredLanguage(event: Select2UpdateEvent<any>) {
    this.userUpdateAccount.preferredLanguage = event.value;
  }


  ngOnDestroy(): void {
   
  }
}
