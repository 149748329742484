import { Injectable, OnDestroy } from '@angular/core';
import { Quotation } from '../models/quotation';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, tap, take, Subscription, Subject } from 'rxjs';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { AppConfigService } from 'src/app/shared/config/app-config.service';


@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  url = 'http://localhost:8081/quotations/live/sme';
  quotationDataList: Quotation[] = [];
  private sseStream!: Subscription;
  private apiEndpoints: any;

  constructor(private http: HttpClient, private sseService: EventSourceService, private sessionStorageService: SessionStorageService, private configService: AppConfigService) {
    this.apiEndpoints = configService.getApiEndpoints();
  }

  getLiveQuotation(smeUserId: number): Observable<{ quotations: Quotation[], isTerminated: boolean }> {
    const endpoint = this.apiEndpoints.quotation.getLiveQuotation.replace('${smeUserId}', smeUserId.toString());
    const url = this.configService.getEndpointUrl(endpoint);

    return new Observable<{ quotations: Quotation[], isTerminated: boolean }>((observer) => {
      this.sseStream = this.sseService.observeMessages(url)
        .subscribe((message: any) => {
          if (message.id == null) {
            observer.next({ quotations: this.quotationDataList, isTerminated: true });
            this.sseStreamDestroy();
            observer.complete();
          } else {
            this.quotationDataList.push(message);
            observer.next({ quotations: this.quotationDataList, isTerminated: false });
          }
        });
    });
  }

  getQuotationByQuotationGroup(quotationGroupId: number): Observable<any> {
    
    const endpoint = this.apiEndpoints.quotation.getQuotationByQuotationGroup.replace('${quotationGroupId}', quotationGroupId.toString());
    const url = this.configService.getEndpointUrl(endpoint);

    return this.http.get(url);
      
  }

  getActiveQuotation(): Observable<any> {
    
    const endpoint = this.apiEndpoints.quotation.getActiveQuotation;
    const url = this.configService.getEndpointUrl(endpoint);

    return this.http.get(url);
      
  }

  getCountActiveQuotation(): Observable<any> {
    
    const endpoint = this.apiEndpoints.quotation.getCountActiveQuotation;
    const url = this.configService.getEndpointUrl(endpoint);

    return this.http.get(url);
      
  }

  

  sseStreamDestroy(): void {
    if (this.sseStream) {
      console.log("sseStream Destroy");
      this.sseStream.unsubscribe();
      this.quotationDataList = [];
    }
  }
  
}
  
  

