import { Injectable } from '@angular/core';
import { SmeUser } from '../models/sme-user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { KeycloakService } from 'keycloak-angular';
import { AppConfigService } from 'src/app/shared/config/app-config.service';


const baseUrl = 'http://localhost:8080/api/sme';

@Injectable({
  providedIn: 'root'
})

export class SMEService {
  
  private sme: SmeUser[] = [];
  private apiEndpoints: any;

  constructor(private http: HttpClient, private readonly keycloak: KeycloakService, private configService: AppConfigService) {
      this.apiEndpoints = configService.getApiEndpoints();
      console.log('this.apiEndpoints ==>', this.apiEndpoints);
   }

    searchSmeByCR(smeCR: any, mockdata: Boolean): Observable<SmeUser> {

      const endpoint = this.apiEndpoints.sme.searchSmeByCR.replace('${smeCR}', smeCR.toString()); //this.configService.getApiUrl('addMember');
      let url = this.configService.getEndpointUrl(endpoint);
      
      if(mockdata){
        url = 'assets/api-mock-data/sme-data.json';
      }

      return this.http.get<SmeUser>(url);
    }

    getSmeUserById(smeUserId: number, mockdata: Boolean): Observable<SmeUser> {

      const endpoint = this.apiEndpoints.sme.getSmeUserById.replace('${smeUserId}', smeUserId.toString()); //this.configService.getApiUrl('addMember');
      let url = this.configService.getEndpointUrl(endpoint);

      if(mockdata){
        url = 'assets/api-mock-data/sme-data.json';
      }

      return this.http.get<SmeUser>(url);
    }

    updateSmeUserById(smeUserId: number, data: any): Observable<any> {
      const endpoint = this.apiEndpoints.sme.updateSmeUserById.replace('${smeUserId}', smeUserId.toString()); //this.configService.getApiUrl('addMember');
      const url = this.configService.getEndpointUrl(endpoint);
      return this.http.patch(url, data);
    }

    updateSmeUserBySmeIdAndUserId(smeId: number, userId: string, data: any): Observable<any> {
      const endpoint = this.apiEndpoints.sme.updateSmeUserBySmeIdAndUserId.replace('${smeId}', smeId.toString()).replace('${userId}', userId.toString()); //this.configService.getApiUrl('addMember');
      const url = this.configService.getEndpointUrl(endpoint);

      return this.http.patch(url, data);
    }
    

}