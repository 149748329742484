import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { PubSubService } from '../shared/services/pub-sub.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})

export class AuthComponent implements OnInit {

  constructor(private readonly keycloak: KeycloakService) {}

  ngOnInit(): void {

    this.keycloak.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        
        // User is logged in, perform necessary actions
        console.log('User is logged in');
        
        // Reload the parent window  
        if (window.opener) {
            window.opener.location.reload();
            window.close();
        }
        
      } else {
        // User is not logged in or login failed
        console.log('User is not logged in');
        // Handle failed login or additional actions

        this.keycloak.login();
      }
    });
    
  }
    
}

