
<div class="border border-0 rounded shadow">
    <div class="tabs tabs-style-1 ">
                                
        <ul id="profilePasswordLinkUl" class="nav nav-pills nav-justified flex-column flex-sm-row rounded" role="tablist">
            <li class="nav-item" role="presentation">
                <a routerLink="/user-board/settings/infos" class="nav-link rounded active" [class.active]="tab=='infos'" id="infos" role="tab" aria-selected="true">
                    
                    <span class="tab-title">حسابي</span>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a routerLink="/user-board/settings/password" class="nav-link rounded" [class.active]="tab=='password'" id="password" role="tab" aria-selected="false">
                    <span class="tab-title">تغيير كلمة المرور</span>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a routerLink="/user-board/settings/phone" class="nav-link rounded" [class.active]="tab=='phone'" id="phoneNumber" role="tab" aria-selected="false">
                    <span class="tab-title">تغيير رقم الجوال</span>
                </a>
            </li>
            <li class="nav-item nav-end" role="presentation">
                <a routerLink="/user-board/settings/email" class="nav-link rounded" [class.active]="tab=='email'" id="email" role="tab" aria-selected="false">
                    <span class="tab-title">تغيير البريد الالكتروني</span>
                </a>
            </li>
        </ul>

        <div *ngIf="tab=='infos'" class="tab-content   p-lg-5 p-4">
            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab-1">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-row">
                            <label>اسمك الاول <span class="required-astrix"> *</span></label>
                            <input [(ngModel)]="userUpdateAccount.firstName" autocomplete="off" class="form-control pl-5" data-val="true" data-val-regex="برجاء ادخال حروف فقط"  id="FullName" maxlength="80" name="firstName" placeholder="ادخل اسم جهة التواصل" tabindex="1" type="text" [value]="firstName">
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="form-row">
                            <label>اسمك الثاني <span class="required-astrix"> *</span></label>
                            <input [(ngModel)]="userUpdateAccount.lastName" autocomplete="off" class="form-control pl-5" data-val="true" data-val-regex="برجاء ادخال حروف فقط"  id="FullName" maxlength="80" name="lastName" placeholder="ادخل اسم جهة التواصل" tabindex="1" type="text" [value]="lastName">
                        </div>
                    </div>
                </div>
                
                <div class="row" style="margin-top: 15px;">
                    <div class="col-md-6">
                        <div class="form-row select-lg">
                            <label>اللغة المفضلة <span class="required-astrix"> *</span></label>
                           

                            <select2 
                                [(ngModel)]="userUpdateAccount.preferredLanguage"
                                [data]="langSelect2" 
                                [value]="preferredLanguage" 
                                id="LanguagePrefered" name="PreferredLanguage"
                                style="width:100%"
                                class="select2 select2-container select2-container--default select2-container--focus"
                            >
                            </select2>

                            <span class="field-validation-valid" data-valmsg-for="PreferredLanguage" data-valmsg-replace="true"></span>
                        </div>
                    </div>
                </div>
                
                <div class="row mt-4">
                    <div class="col-sm-12">
                        <button (click)="updateUserInfos()" class="btn btn-lg btn-wide btn-style-1" id="saveProfilebtn" tabindex="5" title="حفظ">حفظ</button>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="tab=='password'" class="tab-content p-lg-5 p-4">
            
                <div class="row">
                        <div class="col-sm-4">
                            <div class="form-row">
                                <label>كلمة المرور الجديدة <span class="required-astrix"> *</span></label>

                                <input [(ngModel)]="userUpdatePassword.password" class="form-control pl-5" data-val="true" data-val-minlength="كلمة المرور يجب ألا يقل طولها عن 6 خانات" data-val-minlength-min="6" data-val-required="كلمة المرور الجديدة مطلوبة" id="NewPassword" maxlength="20" name="NewPassword" oncopy="if (!window.__cfRLUnblockHandlers) return false; return false" oncut="if (!window.__cfRLUnblockHandlers) return false; return false" placeholder="كلمة المرور الجديدة" tabindex="2" type="password">
                                
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-row">
                                <label>تأكيد كلمة المرور<span class="required-astrix"> *</span></label>

                                <input [(ngModel)]="userUpdatePassword.confirmPassword"  class="form-control pl-5" data-val="true" data-val-equalto="كلمة المرور وتأكيد كلمة المرور غير متطابقين" data-val-equalto-other="NewPassword" data-val-required="تأكيد كلمة المرور مطلوب" id="ConfirmPassword" maxlength="20" name="ConfirmPassword" oncopy="if (!window.__cfRLUnblockHandlers) return false; return false" oncut="if (!window.__cfRLUnblockHandlers) return false; return false" onpaste="if (!window.__cfRLUnblockHandlers) return false; return false" placeholder="تأكيد كلمة المرور" tabindex="3" type="password">


                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-12">
                            <button (click)="updateUserPassword()" class="btn btn-lg btn-wide btn-style-1">حفظ</button>
                        </div>
                    </div>
                    <div class="col-sm-6">
                    </div>
        </div>

        <div *ngIf="tab=='phone'" class="tab-content p-lg-5 p-4">
            <div *ngIf="requireOtp!=true" class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab-1">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-row">
                            <label>رقم الجوال الحالي</label>
                            <input type="text" [value]="phoneNumber" class="form-control pl-5" disabled="" style="background-color: rgb(241,241,241);" dir="ltr">
                        </div>
                    </div>
                    <!--<div class="col-sm-4">
                            <div class="form-row">
                                <label>كلمة المرور الخاصة بك <span class="required-astrix">*</span></label>
                                <input autocomplete="new-password" class="form-control pl-5" data-val="true" data-val-required="كلمة المرور مطلوبة" id="Password" maxlength="20" name="Password" placeholder="كلمة المرور" tabindex="2" type="password">
                                
                            </div>
                    </div>-->
                    <div class="col-sm-4">
                        <div class="form-row">
                            <label>رقم الجوال الجديد <span class="required-astrix">*</span></label>
                            <input [(ngModel)]="userUpdatePhoneNumber.phoneNumber" autocomplete="off" class="form-control pl-5" data-val="true" data-val-regex="أدخل رقم جوال صحيح مكون من 10 أرقام" data-val-regex-pattern="^05[0-9]{8}$" data-val-required="ادخال رقم الجوال مطلوب" id="MobileNo" maxlength="10" name="MobileNo"  placeholder="05XXXXXXXX" type="text" value="">
                            
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-12">
                            <button (click)="requestPhoneUpdate()" class="btn btn-lg btn-wide btn-style-1">حفظ</button>
                        </div>
                    </div>
                    <div class="col-sm-6">
                    </div>
                </div>
                
            </div>
            <app-otp-verification (resendAction)="requestPhoneUpdate()" (confirmAction)="verifyPhoneUpdate($event)" *ngIf="requireOtp==true"></app-otp-verification>
        </div>


        <div *ngIf="tab=='email'" class="tab-content p-lg-5 p-4">
            <div class="tab-pane fade active show" id="tab2" role="tabpanel" aria-labelledby="tab-2">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-row">
                            <label>البريد الالكتروني الحالي</label>
                            <input type="text" placeholder="" [value]="email" disabled="" class="form-control pl-5" style="background-color: rgb(241,241,241);">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-row">
                            <label>البريد الالكتروني الجديد <span class="required-astrix"> *</span></label>
                            <input [(ngModel)]="userUpdateEmail.email" autocomplete="off" class="form-control pl-5" data-val="true" data-val-email="البريد الالكتروني غير صحيح" data-val-regex="البريد الالكتروني غير صحيح" data-val-required="ادخال البريد الالكتروني مطلوب" id="NewEmail" maxlength="200" name="NewEmail" oncopy="if (!window.__cfRLUnblockHandlers) return false; return false" onpaste="if (!window.__cfRLUnblockHandlers) return false; return false" placeholder="البريد الالكتروني" tabindex="1" type="text" value="">
                            <span class="field-validation-valid" data-valmsg-for="NewEmail" data-valmsg-replace="true"></span>
                            <label id="emailExist" class="field-validation-error"></label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-row">
                            <label>تأكيد البريد الالكتروني الجديد <span class="required-astrix"> *</span></label>
                            <input [(ngModel)]="userUpdateEmail.confirmEmail" autocomplete="off" class="form-control pl-5" data-val="true" data-val-email="البريد الالكتروني غير صحيح" data-val-equalto="البريد الإلكتروني وتأكيد البريد الإلكتروني غير متطابقين" data-val-equalto-other="*.NewEmail" data-val-regex="البريد الالكتروني غير صحيح" data-val-required="تأكيد البريد الالكتروني مطلوب" id="ConfirmEmail" maxlength="200" name="ConfirmEmail" onpaste="if (!window.__cfRLUnblockHandlers) return false; return false;" placeholder="تأكيد البريد الإلكتروني" tabindex="2" type="text" value="">
                            <span class="field-validation-valid" data-valmsg-for="ConfirmEmail" data-valmsg-replace="true"></span>

                        </div>
                    </div>
                        <!--<div class="col-sm-6">
                            <div class="form-row">
                                <label>كلمة المرور الخاصة بك <span class="required-astrix"> *</span></label>
                                <input autocomplete="new-password" class="form-control pl-5" data-val="true" data-val-required="كلمة المرور مطلوبة" id="AccountPassword" maxlength="20" name="AccountPassword" tabindex="3" type="password" value="">
                                <span class="field-validation-valid" data-valmsg-for="AccountPassword" data-valmsg-replace="true"></span>
                                <label id="incorrectPassword" class="field-validation-error"></label>
                            </div>
                        </div>-->
                </div>
                <div class="row mt-4">
                    <div class="col-sm-12 text-center">
                        <button (click)="updateUserEmail()" class="btn btn-lg btn-wide btn-style-1 m-1" title="حفظ">حفظ</button>
                    </div>
                </div>
        </div>
        </div>
    </div>
</div>
                    
