import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StepTabChildInterface } from '../shared/models/step-tab-child';
import { PaymentService } from './services/payment-service';
import { PaymentCheckout } from './models/payment-checkout';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements StepTabChildInterface, OnInit, OnDestroy, AfterViewInit {

  orderId!: number;
  paymentCheckout!: PaymentCheckout;
  paymentCheckoutCode!: String;

  constructor(
    private router: Router,
    private route: ActivatedRoute, 
    private paymentService: PaymentService,
    private renderer: Renderer2,
    private spinnerService: NgxSpinnerService
  ) {}


  ngOnInit(): void {
    this.spinnerService.show();

    this.route.params.subscribe(params => {
      this.orderId = params['oId'];
      this.prepareCheckout();
      
    });
  }

  ngAfterViewInit() {
  }

  //https://wordpresshyperpay.docs.oppwa.com/tutorials/integration-guide
  //1. Prepare the checkout : Set all required information in backend by orderId 
  prepareCheckout(){
    this.paymentService.prepareCheckout({'orderId': this.orderId}).subscribe({
      next: (data) => {
        this.paymentCheckout = data;
        this.paymentCheckoutCode = this.paymentCheckout.paymentBrand;
        this.loadGatewayUI(this.paymentCheckout.checkoutId);
      }
    })
  }

  //2. Create the payment form
  loadGatewayUI(checkoutId: string): void{
    const script = this.renderer.createElement('script');
    script.src = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId='+checkoutId;
    console.log('script.src =>', script.src);
    this.renderer.appendChild(document.body, script);

    // Create a script element
    const scriptWpwlOptions = this.renderer.createElement('script');
    scriptWpwlOptions.innerHTML = 'var wpwlOptions = {style:"plain"};'; // Change card design to flat intead of explicit card
    scriptWpwlOptions.innerHTML = 'var wpwlOptions = {locale: "ar"};'; 

    this.renderer.appendChild(document.head, scriptWpwlOptions); 
    this.spinnerService.hide(); 
 
  }

 

  ngOnDestroy(): void {
    
  }

  saveAllOnNextAction(): void {
    
  }


}
