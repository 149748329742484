import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MemberService } from '../../services/member.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { PubSubService } from 'src/app/shared/services/pub-sub.service';
import { DisclosureService } from '../../services/disclosure.service';
import { Disclosure } from '../../models/disclosure';
import { MemberDisclosure } from '../../models/member-disclosure';
import { NgSwitch } from '@angular/common';
import { MemberDisclosureStats } from '../../models/member-disclosure-stats';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-member-disclosure',
  templateUrl: './member-disclosure.component.html',
  styleUrls: ['./member-disclosure.component.scss']
})
export class MemberDisclosureComponent implements OnInit, OnDestroy {

  @Input() memberId!: number; // Declare the input property
  @Input() disclosure!: Disclosure[];
  @Output() disclosureStats: EventEmitter<any> = new EventEmitter<any>();

  memberDisclosure: MemberDisclosure[] = [];
  newMemberDisclosure!: MemberDisclosure;
  
  constructor(private disclosureService: DisclosureService, 
              private sessionStorageService: SessionStorageService,
              private datePipe: DatePipe, 
              private router: Router){}


  ngOnInit(){
    console.log("disclosureeee =================++>", this.disclosure)
    this.getMemberDisclosures();
    this.setDisclosureStats();
  }

  getMemberDisclosures(): void {
    this.disclosureService.getMemberDisclosures(this.memberId).subscribe({
      next: (data) => {
        
        this.memberDisclosure = data;
      
        this.setDisclosureStats();
      },
      error: (e) => console.error(e)
    });
  }

  isDisclosureSelected(disclosureId: number): boolean {
    
    const savedDisclosure = this.memberDisclosure.find(d => (d.memberId === this.memberId && d.disclosureId === disclosureId));
    if(!!savedDisclosure && (savedDisclosure.value=="1")){
      return true;
    } else {
      
      if(!savedDisclosure){ // Disclosure does not exists in this.memberDisclosure, init it
        this.newMemberDisclosure = {
          memberId: this.memberId,
          disclosureId: disclosureId,
          value: null
        };  
        this.memberDisclosure.push(this.newMemberDisclosure);
      }

      const radio = document.getElementById(`${this.memberId}_${disclosureId}_no`);
      radio?.setAttribute('checked', 'checked');

      /*if(this.memberId == 6){
        console.log('this.memberDisclosure =>', this.memberDisclosure);

      }*/

      return false;
    }
    

    
  }

  disclosureValue(disclosureId: number){
    const savedDisclosure = this.memberDisclosure.find(d => (d.memberId === this.memberId && d.disclosureId === disclosureId));
    if(savedDisclosure) return savedDisclosure.value;
    return '';
  }

  onDisclosureInputChange(disclosureId: number, disclosureInputType: string, event: any): void {
    
    let value = null;
    
    if(disclosureInputType == 'YESNO_RADIO' || disclosureInputType =='TEXT' || disclosureInputType =='DATE'){
      value = event.target.value;
      const foundIndex = this.memberDisclosure.findIndex(
        (memberDisclosure) => memberDisclosure.disclosureId === disclosureId
      );
        
      // If the newMemberDisclosure is already set, update it
      if (foundIndex !== -1) {
          this.memberDisclosure[foundIndex].value = value;
      } else { // initialize new newMemberDisclosure
        
          this.newMemberDisclosure = {
            memberId: this.memberId,
            disclosureId: disclosureId,
            value: value
          };  
          this.memberDisclosure.push(this.newMemberDisclosure);
      }
    } else if(disclosureInputType == 'MULTI_CHOICES_RADIO'){
      this.onDisclosureRadioChange(disclosureId, event);
    }
      
    //Publish disclosureStats to parent component
    this.setDisclosureStats();
  }

  onDisclosureRadioChange(selectedDisclosureId: number, event: Event): void {
    
    const changedRadio = event.target as HTMLInputElement;
    const groupName = changedRadio.name;
    const radioButtons = document.querySelectorAll(`input[type="radio"][name="${groupName}"]`) as NodeListOf<HTMLInputElement>;
  
    // Extract IDs of all radio buttons in the group
    const radioIds = Array.from(radioButtons).map((radio) => parseInt(radio.id, 10));
  
    // Clear all radio indices from this.newMemberDisclosure
    radioIds.forEach((id) => {
      const index = this.memberDisclosure.findIndex((disclosure) => disclosure.disclosureId === id);
      if (index !== -1) {
        this.memberDisclosure.splice(index, 1);
      }
    });
  
    // Initialize again all radio buttons in this.newMemberDisclosure with yesNoResponse = false for all
    radioIds.forEach((id) => {
      this.memberDisclosure.push({
        memberId: this.memberId,
        disclosureId: id,
        value: null // or whatever default value you want for text inputs
      });
    });
  
    // Set yesNoResponse = true for the selected radio button
    const selectedDisclosureIndex = this.memberDisclosure.findIndex(
      (disclosure) => disclosure.disclosureId === selectedDisclosureId
    );
    if (selectedDisclosureIndex !== -1) {
      this.memberDisclosure[selectedDisclosureIndex].value = "1";
    }

    //Publish disclosureStats to parent component
    this.setDisclosureStats();
  }

  setDisclosureStats(): void {
    const YESCount = this.memberDisclosure.filter((disclosure) => disclosure.value === '1').length;
    const disclosureStats: MemberDisclosureStats = {
      toal: this.memberDisclosure.length,
      NOCount: this.memberDisclosure.filter((disclosure) => disclosure.value === '0').length,
      YESCount: YESCount
    };
    this.disclosureStats.emit({ memberId: this.memberId, stats: disclosureStats });
  }
  

  addMemberDisclosures(): void{
    //this.memberDisclosure.newPolicyStartDate = this.datePipe.transform(this.smeDataList.newPolicyStartDate, 'yyyy-MM-dd');
    this.disclosureService.addMemberDisclosures(this.memberId, this.memberDisclosure).subscribe({
      next: (res) => {
        console.log("post memberDisclosures - this.memberId => ", this.memberId);
        console.log("post memberDisclosures => ", this.memberDisclosure);
      },
      error: (e) => console.error(e)
    });
  }

  passToQuotation(): void{
    this.router.navigate(['/quotation']); // Adjust as per your data structure
  }

  ngOnDestroy(): void {
    
    
  }
}
