<!-- Hero Start     style="background: url('/assets/images/masarat/masarat-bg-home.png') center center;"-->
<section class="bg-half-260 d-table  bg-primary w-100"
   style="background-image: url('assets/images/hosting/domain.png');"
   id="home">
   <div class="bg-overlay"></div>
   <div class="container">
      <div class="row mt-5 justify-content-center">
         <div class="col-lg-12">
            <div class="title-heading text-center">
               <h1 class="heading title-dark text-white mb-3">منصة <span class="text-primary" style="font-weight: 500;color: #0b879b !important;">مسارات</span> 
                  للتأمين الطبي
               </h1>
               <p class="para-desc text-white mx-auto">
                  لدينا أكثر من<b> 100,000+ </b> شركة معتمدة وأكثر من  <b> 875,000+ </b> عضو يتمتعون بتغطية تأمين طبي . نحن نتعامل مع أكثر من <b> 15+ </b> شركة تأمين مختلفة - نطاق واسع من الخيارات     
               </p>
            </div>
         </div>
      </div>
   </div>
</section>

<div class="search-bar-container">
   <div class="card features feature-primary feature-clean explore-feature p-2  px-md-3 border-0 rounded-sm shadow-1  col-lg-6">
      <div class="card-body">
         <form class="login-form">
            <div class="row">
               <div class="col-lg-12">
                  <div class="form-group position-relative">
                     <label>رقم السجل التجاري للشركة أو الرقم الموحد للمنشأة <span class="text-danger">*</span></label>
                     <i data-feather="key" class="fea icon-sm icons"></i>
                     <input type="text" 
                     class="form-control pl-5" placeholder="xxxxxxxxxx/70xxxxxxxx" required=""
                     [(ngModel)]="smeCR" autocomplete="off" class="form-control" data-val="true" data-val-regex="ادخل رقم سجل تجاري أو رقم حاسب صحيح" 
                     data-val-required="مطلوب رقم السجل التجاري للشركة أو الرقم الموحد للمنشأة" id="ComputerNoORCRNo" inputmode="numeric" 
                     maxlength="10" name="ComputerNoORCRNo" onkeypress="if (!window.__cfRLUnblockHandlers) return false; return isNumberKey(event)" value="">
                  </div>
               </div>
               <div class="col-lg-12" >
                  <a (click)="searchSME()" class="btn buy-btn  shadow mt-3">
                  اشتري الآن
                  <i class="mdi mdi-chevron-left align-middle"></i>
                  </a>
               </div>
            </div>
         </form>
      </div>
   </div>
</div>




<!-- Start -->
<section class="section border-bottom">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6">
                <div class="custom-card card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-start">
                        <i class="uil uil-comparison rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">                                            
                        <a href="javascript:void(0)" class="title h5 text-dark">مقارنة سهلة</a>
                        <p class="text-muted mt-2">عروض أسعار فورية لعدة منتجات من عدة شركات تأمين معتمدة واللي تقدر تقارن بينها بسهولة</p>
                        
                        <i class="uil uil-comparison  full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="custom-card card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-start">
                        <i class="uil uil-link rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">                                            
                        <a href="javascript:void(0)" class="title h5 text-dark">ربط في CCHI</a>
                        <p class="text-muted mt-2">ربط فوري وإلكتروني في أبشر ومجلس الضمان الصحي التعاوني خلال 24 إلى 48 ساعة وأقل</p>
                        
                        <i class="uil uil-link full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="custom-card card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-start">
                        <i class="uil uil-eye rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">                                            
                        <a href="javascript:void(0)" class="title h5 text-dark">معك لحظة بلحظة</a>
                        <p class="text-muted mt-3">نتابع مطالباتك ونحرص على تخليص الموافقات الطبية لجميع الفئات في أقرب الاجال </p>
                        
                        <i class="uil uil-eye full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="custom-card card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-start">
                        <i class="uil uil-stopwatch rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">                                            
                        <a href="javascript:void(0)" class="title h5 text-dark">ما نضيع وقتك</a>
                        <p class="text-muted mt-2">بخطوات بسيطة راح تقدر تشتري وثيقة التأمين الطبي لشركتك وبشكل فوري</p>
                        
                        <i class="uil uil-stopwatch full-img"></i>
                    </div>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="custom-card card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-start">
                        <i class="uil uil-file-check-alt rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">                                            
                        <a href="javascript:void(0)" class="title h5 text-dark">معلومات كاملة</a>
                        <p class="text-muted mt-2">ليس سعر الوثيقة هو المهم فقط، تقدر تشوف سعر كل مؤمن له عندك، وتشوف قوائم مقدمي الرعاية الطبية وحدود التغطيات</p>
                        
                        <i class="uil uil-file-check-alt full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="custom-card card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-start">
                        <i class="uil uil-file-download-alt rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">                                            
                        <a href="javascript:void(0)" class="title h5 text-dark">تجديد</a>
                        <p class="text-muted mt-2">تيح منصة مسارات تجديد الوثيقة اون لاين من خلال اشعارك بموعد إنتهاء التأمين بكل يسر وسهولة</p>
                        
                        <i class="uil uil-file-download-alt full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="custom-card custom-card card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-start">
                        <i class="uil uil-hospital-square-sign rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">                                            
                        <a href="javascript:void(0)" class="title h5 text-dark">منافع وشبكات متعددة</a>
                        <p class="text-muted mt-2">اطلع على تفاصيل وثيقتك وحدود تغطيتها في أي وقت</p>
                        
                        <i class="uil uil-hospital-square-sign full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="custom-card card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-start">
                        <i class="uil uil-user-plus rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">                                            
                        <a href="javascript:void(0)" class="title h5 text-dark">إضافة وحذف الأعضاء</a>
                        <p class="text-muted mt-2">سهل إنك تضيف أو تلغي أي عضو اونلاين</p>
                        
                        <i class="uil uil-user-plus full-img"></i>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

</section><!--end section-->
<!-- End -->
    

<section class="section" style="background-color: white">
    <div class="container mt-50 mt-60" style="padding-bottom: 100px;">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <!--<h6 class="text-primary">Work Process</h6>-->
                    <h4 class="title mb-4">كيف أتحصل على التأمين الطبي مع مسارات ؟</h4>
                    <p class="text-muted para-desc mx-auto mb-0">البحث عن وثيقة تأمين طبي وتواصلك مع شركات التأمين يكلفك وقت وجهد، وخاصة لو كنت تبحث عن اللي يناسب شركتك أو ميزانيتك، <span class="text-primary fw-bold">منصة مسارات</span> تختصر لك المشوار في خطوات سهلة و سريعة</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-md-4 mt-4 pt-2">
                <div class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-save rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">ادخال البيانات</h5>
                        <p class="text-muted mb-0">ادخل معلومات الموظفين والتابعين لشركتك</p>
                    </div>
                </div>
            </div><!--end col-->
            
            <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                <div class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-chart-growth-alt h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">مقارنة عروض الأسعار</h5>
                        <p class="text-muted mb-0">قارن بين عروض الأسعار، الشبكات والمنافع</p>
                    </div>
                </div>
            </div><!--end col-->
            
            <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                <div class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-invoice rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">شراء الوثيقة</h5>
                        <p class="text-muted mb-0">اختر عرض السعر وادفع بشكل إلكتروني واحصل على الوثيقة</p>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

<section class="section" style="background: url('assets/images/hosting/bg-home.jpg') center center fixed;">
    <div class="bg-overlay-index"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-4 py-3">
                <div class="counter-box text-center">
                    <h1 class="mb-0 text-white fw-bold"><span class="counter-value" data-target="1548">1548</span>+</h1>
                    <h5 class="counter-head text-white">مطالبة طبية سنويا</h5>
                </div><!--end counter box-->
            </div><!--end col-->

            <div class="col-md-4 py-3">
                <div class="counter-box text-center">
                    <h1 class="mb-0 text-white fw-bold"><span class="counter-value" data-target="25">100</span>+</h1>
                    <h5 class="counter-head text-white">شركة تأمن معنا سنويا</h5>
                </div><!--end counter box-->
            </div><!--end col-->

            <div class="col-md-4 py-3">
                <div class="counter-box text-center">
                    <h1 class="mb-0 text-white fw-bold"><span class="counter-value" data-target="9">13</span>+</h1>
                    <h5 class="counter-head text-white">سنة خبرة في مجال التأمين الطبي</h5>
                </div><!--end counter box-->
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>
    
<div class="container mt-100 mt-60">
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="section-title text-center mb-4 pb-2">
                <h4 class="mb-4">معلومات تهمك حول مسارات التأمين الطبي للمنشآت!</h4>
                <p class="para-desc mx-auto text-muted">التأمين الطبي للمؤسسات، والمعروف أيضًا باسم التأمين الصحي الجماعي، مصمم لتغطية النفقات الطبية لموظفيك وعائلاتهم. هذه بعض المعلومات المهمة للاستفادة بشكل صحيح من هذه الخدمة                </p>
            </div>
        </div><!--end col-->
    </div><!--end row-->

    <div class="row justify-content-center">
        <div class="col-lg-9 mt-4 pt-2">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item rounded">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne">
                            كيف يمكنني شراء تأمين طبي لشركتي؟
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body text-muted">
                            يمكنك ذلك عبر إنشاء طلب جديد وتعبئة معلومات الشركة والموظفين والتابعين.
                        </div>
                    </div>
                </div>
                
                <div class="accordion-item rounded mt-2">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                            aria-expanded="false" aria-controls="collapseTwo">
                            هل يمكن شراء تأمين طبي للأفراد؟
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body text-muted">
                            لا يمكن شراء تأمين طبي للأفراد عبر تأميني للمنشآت، تأميني يقوم بتوفير تأمين طبي للمنشآت الصغيرة والمتوسطة.
                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded mt-2">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            كيف يمكن لمسارات مساعدتي في العثور على أفضل تأمين طبي للمنشآت؟
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body text-muted">
                            يمكن أن تساعدك مسارات في مقارنة الخطط من شركات التأمين المتعددة. يمكن أن يساعدك هذا في العثور على الخطة التي توفر أفضل تغطية بأقل الأسعار.                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded mt-2">
                    <h2 class="accordion-header" id="heading4">
                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                            هل يمكنني المقارنة بين المنافع والشبكات لوثائق التأمين؟
                        </button>
                    </h2>
                    <div id="collapse4" class="accordion-collapse border-0 collapse" aria-labelledby="heading4"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body text-muted">
                            يتيح تأميني المقارنة بين المنافع وشبكات مزودي الخدمة (مستشفيات، مستوصفات، مراكز طبية...الخ) بعد إدخال معلومات الشركة والمؤمن لهم بشكل صحيح والحصول على عروض أسعار.
                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded mt-2">
                    <h2 class="accordion-header" id="heading5">
                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                            ما هي أنواع النفقات الطبية التي يتم تغطيتها عادة في التأمين الصحي؟
                        </button>
                    </h2>
                    <div id="collapse5" class="accordion-collapse border-0 collapse" aria-labelledby="heading5"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body text-muted">
                            يختلف هذا اعتمادًا على الخطة التي تختارها، ولكنه يمكن أن يغطي العلاج في المستشفى، والجراحة، وزيارات الطبيب، والأدوية الموصوفة، والرعاية الوقائية، وخدمات الصحة العقلية، والمزيد.
                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded mt-2">
                    <h2 class="accordion-header" id="heading6">
                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                            ظهور خطأ "رقم الإقامة غير صحيح أو مدخل بشكل خاطىء"
                        </button>
                    </h2>
                    <div id="collapse6" class="accordion-collapse border-0 collapse" aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body text-muted">
                            رقم الإقامة عبارة عن عشرة أرقام تبدأ بـ 2
                        </div>
                    </div>
                </div>
                
            </div>
        </div><!--end col-->
    </div><!--end row-->
</div><!--end container-->
</section><!--end section-->
<!-- End -->



<!-- Back to top -->
<a href="#" onclick="topFunction()" id="back-to-top" class="back-to-top fs-5"><i data-feather="arrow-up" class="fea icon-sm icons align-middle"></i></a>
<!-- Back to top -->



<section class="section pt-5 pb-5">
   <div class="container" >
      <div class="row">
         <div class="col-md-4 col-12" >
            <div class="features feature-primary text-center">
               <div class="image position-relative d-inline-block">
                  <img src="/assets/images/masarat/insurance-authority.svg" class="avatar avatar-small" alt="">
               </div>
               <div class="content mt-4">
                  <h5>  مفوض من</h5>
                  <p class="text-muted mb-0"><b>هيئة التأمين السعودي</b>
                  </p>
               </div>
            </div>
         </div>
         <!--end col-->
         <div class="col-md-4 col-12">
            <div class="features feature-primary text-center">
               <div class="image position-relative d-inline-block">
                  <img src="/assets/images/masarat/monshaat-logo.svg" class="avatar avatar-small" alt="">
               </div>
               <div class="content mt-4">
                  <h5>برعاية</h5>
                  <p class="text-muted mb-0">
                     <b>منشآت</b> 
                  </p>
               </div>
            </div>
         </div>
         <!--end col-->
         <div class="col-md-4 col-12">
            <div class="features feature-primary text-center">
               <div class="image position-relative d-inline-block">
                  <img src="/assets/images/masarat/mazaya.png" class="avatar avatar-small" alt="">
               </div>
               <div class="content mt-4">
                  <h5>
                     متاح على
                  </h5>
                  <p class="text-muted mb-0">
                     <b>خدمات مزايا</b> 
                  </p>
               </div>
            </div>
         </div>
         <!--end col-->
      </div>
      <!--end row-->
   </div>
   <!--end container-->
</section>

