<section *ngIf="showOrderDetails === true" role="tabpanel" class="body" aria-hidden="false" class="slide-in">
    <div class="stepform-section centerpaddingbox">
      <div id="ErrorMessage" class="alert alert-danger" style="display: none;"></div>
        <div class="row">
            <div class="col col-lg-8 col-sm-12">
      <form id="frmSummary" method="post" enctype="multipart/form-data" action="https://health.tameeni.com/Summary/PolicyPayment" novalidate="novalidate">
        <input type="hidden" id="QuoteListData_UserCompanyCRNo" name="QuoteListData.UserCompanyCRNo" value="1010575636">
     
        <input type="hidden" id="QuoteListData_ApplicationID" name="QuoteListData.ApplicationID" value="3456692">
        <input type="hidden" id="ApplicationQuoteID" name="ApplicationQuoteID" value="26881188">
        <input type="hidden" id="hdnHQueryID" name="QuoteListData.HQueryID" value="4317495">
        <input type="hidden" id="hdnHquoteRequestID" name="QuoteListData.HquoteRequestID" value="41681611">
        <input type="hidden" id="QuoteListData_InsuranceCompanyID" name="QuoteListData.InsuranceCompanyID" value="1">
        <input type="hidden" id="QuoteListData_UserCompanyComputerNo" name="QuoteListData.UserCompanyComputerNo" value="7008929783">
        <input type="hidden" id="QuoteListData_UserCompanyName" name="QuoteListData.UserCompanyName" value="شركة شيك تيك لتقنية المعلومات">
        <input type="hidden" id="QuoteListData_GrandTotal" name="QuoteListData.GrandTotal" value="22986.20">
        <input type="hidden" id="QuoteListData_QuoteNumber" name="QuoteListData.QuoteNumber" value="12030667">
        <input type="hidden" id="QuoteListData_CompanyName" name="QuoteListData.CompanyName" value="ملاذ للتأمين التعاوني">
        <input type="hidden" id="SendSmstoPolicyHolder" name="SendSmstoPolicyHolder" value="0">
        <input type="hidden" id="SendtoPolicyHolder" name="SendtoPolicyHolder" value="0">




        <!--Order 1-->
<div class="accordion-item fade-in ">
    <div class="accordion-header bg-light" id="headingTwo">
        <button 
            class="accordion-button collapsed  border-0 rounded"
            [ngClass]="{'border-0': true, 'rounded': isCollapsed}"
            type="button"
            data-bs-toggle="collapse" 
            data-bs-target="#collepseSmeDataContent"
            aria-expanded="true" 
            style="cursor: pointer;"
            (click)="toggleCollapse('isCollapsed',  isCollapsed)"
            aria-controls="collepseSmeDataContent">
            <div class="btn-content">
              <div class="right-site-btn-content">
                <i class="mdi mdi-numeric-1-circle"></i>
              <span class="btn-title">
                تأكيد تفاصيل الوثيقة
              </span>
              </div>
              <div class="left-site-btn-content">
                <i class="mdi mdi-chevron-down" *ngIf="!isCollapsed"></i>
                <i class="mdi mdi-chevron-up" *ngIf="isCollapsed"></i>
              </div>
            </div>
      </button>
  
    </div>

    <!-- End collpse header-->
    <div id="collepseSmeDataContent" class="accordion-collapse  border-0" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        <!--accordion body ( content ) -->
        <div class="accordion-body  border-0  p-lg-5 p-4"    style="background: url('assets/images/hosting/domain.png') 50% 78% ">        
            
              <div id="companydetails" >
                <div class="card-body">

                  <div class="row" style="font-size:85%" >
                    <div class="col-lg-3 col-md-6 col-12 mt-5">
                        <div class="features feature-primary text-center">
                            <div class="image position-relative d-inline-block">
                              <i class="uil uil-building h1 text-primary"></i>
                            </div>

                            <div class="content mt-4">
                                <h5 class="h6"> {{smeCrName}}</h5>
                                <p  class="text-muted mb-0">رقم السجل التجاري : {{smeCrNumber}}</p>
                                <p  class="text-muted mb-0">رقم الكفيل : {{smeCrEntityNumber}}</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-5">
                        <div class="features feature-primary text-center">
                            <div class="image position-relative d-inline-block">
                              <i class="uil uil-bill h1 text-primary"></i>
                            </div>

                            <div class="content mt-4">
                                <h5 class="h6">            
                                  رقم التسعيرة : {{quotationId}}
                                </h5>
                           
                                <p class="text-muted mb-0 text-nowrap">تاريخ بداية التأمين : {{newPolicyStartDate}}</p>
                                <p class="text-muted mb-0 text-nowrap">تاريخ الانتهاء : -</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-5">
                      <div class="features feature-primary text-center">
                          <div class="image position-relative d-inline-block">
                            <i class="uil uil-users-alt h1 text-primary"></i>
                          </div>

                          <div class="content mt-4">
                              <h5 class="h6">            
                                  عدد المؤمن لهم : {{totalMemberCount}}
                              </h5>
                         
                              <p class="text-muted mb-0">موظفين : {{sponsorsMemberCount}}</p>
                              <p class="text-muted mb-0">تابعين : {{dependentsMemberCount}}</p>
                          </div>
                      </div>
                  </div><!--end col-->

                    <div class="col-lg-3 col-md-6 col-12 mt-5">
                        <div class="features feature-primary text-center">
                            <div class="image position-relative d-inline-block">
                              <i class="uil uil uil-award-alt h1 text-primary"></i>
                            </div>

                            <div class="content mt-4">
                              <h5 class="h6"> الفئات مختارة : {{insClassCount}}</h5>
                              <p class="text-muted mb-0"></p>
                              <span class="tagcloud text-center mt-4" *ngFor="let entry of insClassData | keyvalue" [class.box]="true" >
                                  <a href="jvascript:void(0)" class="rounded" [class.bg-soft-success]="entry.key == 'VIP'" [class.bg-soft-footer]="entry.key !== 'VIP'" >{{ entry.key }} : {{ entry.value }}</a>
                              </span>
                            </div>
                        </div>
                    </div><!--end col-->
                  </div>
                    
                  <div class="mt-3">
                    <h6>معلومات المنتج</h6>
                    <ul class="small" style="list-style-type: none;">
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                        <defs>
                        </defs>
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                            <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg>
                        عرض جميع الفئات
                    </li>
                      <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                        <defs>
                        </defs>
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                            <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg>

                        تقديم الاستعاضة الطبية عبر الخدمات الالكترونية</li>
                      <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                        <defs>
                        </defs>
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                            <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg>
                        خدمة المساعدة الدولية للفئة (في في أي بي / فئة أي)</li>
                      <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                        <defs>
                        </defs>
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                            <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg>
                        خدمة توصيل الدواء</li>
                      <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                        <defs>
                        </defs>
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                            <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg>
                        الراي الطبي الاخر</li>
                      <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                        <defs>
                        </defs>
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                            <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg>
                        الموافقات المباشرة الالكترونية</li>
                      <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
                        <defs>
                        </defs>
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                            <path d="M 89.122 3.486 L 89.122 3.486 c -2.222 -3.736 -7.485 -4.118 -10.224 -0.742 L 33.202 59.083 c -1.118 1.378 -3.245 1.303 -4.262 -0.151 L 17.987 43.291 c -3.726 -5.322 -11.485 -5.665 -15.666 -0.693 l 0 0 c -2.883 3.428 -3.102 8.366 -0.533 12.036 L 24.206 86.65 c 2.729 3.897 8.503 3.89 11.222 -0.014 l 6.435 -9.239 L 88.87 10.265 C 90.28 8.251 90.378 5.598 89.122 3.486 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(6,188,66); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg>
                        اختيار الشبكة الطبية</li>
                    </ul>
                    <p class="small mt-3">*الشبكة تخضع للتغيير استناداً لتاريخ تجديد وانتهاء اعتماد مجلس الضمان الصحي التعاوني ومزودي الخدمة</p>
                  </div>
                </div>
              </div>
      
  
  
        </div>
    </div>
  </div>
    <br>

    <!-- Step 2 -->        
<div class="accordion-item fade-in ">
    <div class="accordion-header" id="headingSecond">
        <button 
            class="accordion-button collapsed  border-0 rounded"
            [ngClass]="{'border-0': true, 'rounded': isCollapsed2}"
            type="button"
            data-bs-toggle="collapse" 
            data-bs-target="#collepseContent2"
            aria-expanded="false" 
            style="cursor: pointer;"
            (click)="toggleCollapse('isCollapsed2',  isCollapsed2)"
            aria-controls="collepseContent2">


            <div class="btn-content">
              <div class="right-site-btn-content">
                <i class="mdi mdi-numeric-2-circle"></i>
              <span class="btn-title">
                أدخل رقم ضريبة القيمة المضافة للشركة
              </span>
              </div>
              <div class="left-site-btn-content">
                <i class="mdi mdi-chevron-down" *ngIf="!isCollapsed2"></i>
                <i class="mdi mdi-chevron-up" *ngIf="isCollapsed2"></i>
              </div>
            </div>
      </button>
    </div>
    <!-- End collpse header-->
      <div id="collepseContent2" 
      
      class="accordion-collapse  border-0" 
      aria-labelledby="headingSecond" data-bs-parent="#accordionExample">
          <!--accordion body ( content ) -->
          <div class="accordion-body  border-1  p-lg-5 p-4"    style="background: url('assets/images/hosting/domain.png') 50% 78% ">
              <div id="ErrorMessageVATNumber" class="alert alert-danger" style="display:none;">
                  <span>يرجى ادخال الرقم الضريبي لشركتك</span>
                </div>
                <div id="ErrorMessageVATNumberRegex" class="alert alert-danger" style="display:none;">
                  <span>يرجى ادخال الرقم الضريبي المكون من 15 رقماً بشكل صحيح والذي يبدأ بـ 3XXXXXXXXXXXXXXX</span>
                </div>
                <label class="bold">الرقم الضريبي للشركة</label>
                <input autocomplete="off" class="form-control" 
                  style="margin-bottom: 10px;"
                  id="QuoteListData_CompanyVATNumber" 
                  inputmode="numeric"
                  name="QuoteListData.CompanyVATNumber" onkeypress="return isNumberKey(event)" 
                  placeholder="ادخل الرقم الضريبي"  type="text" value="">
                <div class="form-row custom-checkbox1 mb-0 pb-0">
                  <label class="Form-check">
                    <input class="form-check-input" id="noVatChcbx" type="checkbox" value="true">
                    <span class="Form-label-text">شركتي ليس لديها رقم ضريبي</span>
                  </label>
                </div>
                <div class="form-row mb-0">
                  <label class="bold">اسمك الكامل<span class="text-danger">*</span></label>
                  <input autocomplete="off"
                       style="margin-bottom: 10px;"
                  class="form-control summaryContactName" data-val="true" data-val-maxlength="The field ContactName must be a string or array type with a maximum length of '250'." data-val-maxlength-max="250" data-val-regex="برجاء ادخال حروف فقط" data-val-regex-pattern="^(?:[a-zA-Z\s\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,250}$" data-val-required="ادخال اسمك الكامل مطلوب" id="QuoteListData_ContactName" maxlength="250" name="QuoteListData.ContactName" placeholder="ادخل اسم جهة التواصل" tabindex="1" type="text">
                  <span class="text-danger field-validation-error name-validation-msg field-validation-valid" 
                     
                  data-valmsg-for="QuoteListData.ContactName" data-valmsg-replace="true" id="contactNamevalid"></span>
                </div>
                <div class="form-row mb-0">
                  <label class="bold">رقم الجوال<span class="text-danger">*</span></label>
                  <input autocomplete="off"
                       style="margin-bottom: 10px;"
                  class="form-control summaryMobileNumber" data-val="true" data-val-maxlength="The field ContactMobileNumber must be a string or array type with a maximum length of '10'." data-val-maxlength-max="10" data-val-regex="ادخل رقم جوال صحيح" data-val-regex-pattern="^05[0-9]{8}$" data-val-required="ادخال رقم الجوال مطلوب" id="QuoteListData_ContactMobileNumber" inputmode="numeric" maxlength="10" name="QuoteListData.ContactMobileNumber" onkeypress="return isNumberKey(event)" placeholder="05XXXXXXXX" tabindex="3" type="text">
                  <span class="text-danger field-validation-error field-validation-valid" 
                     
                  data-valmsg-for="QuoteListData.ContactMobileNumber" data-valmsg-replace="true" id="mobileNovalid"></span>
                </div>
                <div class="form-row mb-0">
                  <label class="bold">البريد الالكتروني<span class="text-danger">*</span></label>
                  <input autocomplete="off"
                       style="margin-bottom: 10px;"
                  class="form-control summaryEmail" data-val="true" data-val-email="ادخل بريد الكتروني صحيح" data-val-maxlength="The field ContactEmail must be a string or array type with a maximum length of '256'." data-val-maxlength-max="256" data-val-regex="ادخل بريد الكتروني صحيح" data-val-required="ادخال البريد الالكتروني مطلوب" id="QuoteListData_ContactEmail" maxlength="50" name="QuoteListData.ContactEmail" placeholder="البريد الالكتروني" tabindex="4" type="text">
                  <span class="text-danger field-validation-error field-validation-valid" 
                     
                  data-valmsg-for="QuoteListData.ContactEmail" data-valmsg-replace="true" id="emailvalid"></span>
                </div>
                <div class="form-row custom-checkbox1 mb-0 pb-0">
                  <label class="Form-check">
                    <input type="checkbox" name="PolicyHolderPayment" class="form-check-input" id="PolicyHolderPayment" value="true">
                    <span class="Form-label-text">سيقوم حامل الوثيقة بإكمال الدفع</span>
                  </label>
                </div>
          </div> <!--end body-->
      </div>

</div>
<br>


     <!-- Step 4 -->        
<div class="accordion-item  fade-in">
    <div class="accordion-header" id="heading4">
        <button 
            class="accordion-button collapsed  border-0 rounded"
            [ngClass]="{'border-0': true, 'rounded': isCollapsed4}"
            type="button"
            data-bs-toggle="collapse" 
            data-bs-target="#collepseContent4"
            aria-expanded="false" 
            style="cursor: pointer;"
            (click)="toggleCollapse('isCollapsed4',  isCollapsed4)"
            aria-controls="collepseContent4">

            <div class="btn-content">
              <div class="right-site-btn-content">
                <i class="mdi mdi-numeric-3-circle"></i>
              <span class="btn-title">
                حدد طريقة الدفع
              </span>
              </div>
              <div class="left-site-btn-content">
                <i class="mdi mdi-chevron-down" *ngIf="!isCollapsed2"></i>
                <i class="mdi mdi-chevron-up" *ngIf="isCollapsed2"></i>
              </div>
            </div>
      </button>
  
    </div>
    <!-- End collpse header-->
    <div id="collepseContent4" 
    
    class="accordion-collapse  border-0" 
    aria-labelledby="heading4" data-bs-parent="#accordionExample">
        <!--accordion body ( content ) -->
        <div class="accordion-body  border-1  p-lg-5 p-4"    style="background: url('assets/images/hosting/domain.png') 50% 78% ">
                
            <div class="tabs tabs-style-4 payment-method" id="paymenthideshow">
                <ul class="nav nav-pills nav-justified" id="paymentTab" role="tablist">
                  <li *ngFor="let brand of paymentBrand" class="nav-item" role="presentation">
                    <a (click)="selectPaymentBrand(brand.id)" id="{{brand.id}}" [class.btn-pill]="true" [class.active]="brand.isDefault" [attr.aria-selected]="brand.isDefault" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="">
                      <img [src]="brand.icon">
                      <h5>{{brand.label}}</h5>
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="tab3-1" role="tabpanel">
                    <h5>الدفع عن طريق سداد</h5>
                    <p>سنصدر فاتورة سداد بعد الضغط على زر الدفع الآن</p>
                    <h6>ملحوظة:</h6>
                    <ul class="list-style-note small">
                      <li>سيتم إصدار الوثيقة بمجرد إتمام عملية الدفع.</li>
                      <li>عادةً ما نتلقى دفعة سداد الخاصة بك في غضون دقائق، وفي بعض الحالات، قد يستغرق الأمر ما يصل إلى 4 ساعات لاستلامها بعد إجراء الدفع. كن مطمئنًا، يقوم فريق مسارات بمراقبة كل مدفوعات سداد وسيكون متواجدًا دائمًا للمساعدة.</li>
                    </ul>
                  </div>
                  <div class="tab-pane fade" id="tab3-2" role="tabpanel">
                    <h5>الدفع باستخدام بطاقة ماستركارد الخاصة بك</h5>
                    <p>سيُطلب منك تفاصيل بطاقة ماستركارد الخاصة بك في الصفحة التالية.</p>
                  </div>
                  <div class="tab-pane fade" id="tab3-3" role="tabpanel">
                    <h5>الدفع باستخدام بطاقة فيزا الخاصة بك</h5>
                    <p>سيُطلب منك تفاصيل بطاقة فيزا الخاصة بك في الصفحة التالية.</p>
                  </div>
                  <div class="tab-pane fade" id="tab3-4" role="tabpanel">
                    <h5>الدفع باستخدام بطاقة مدي الخاصة بك</h5>
                    <p>سيُطلب منك تفاصيل بطاقة مدي الخاصة بك في الصفحة التالية.</p>
                  </div>
                </div>
              </div>
  
        </div>
    </div>
  </div>
  
  
       

  
        <div class="form-row custom-checkbox1 mt-4 fade-in">
          <div class="Form-check">
            <input class="form-check-input"
                
             id="IAgreeOnTermsAndConditions"
             name="IAgreeOnTermsAndConditions" 
             type="checkbox" value="true" 
             #iAgreeOnTermsAndConditions (click)="acceptOrder()">
            <span class="Form-label-text">أوافق على <a href="#" target="_blank">الشروط والأحكام</a></span>
          </div>
          <span class="field-validation-error" id="IdTermsAndConditionError"></span>
        </div>
        <div class="form-row custom-checkbox1 pb-0">
          <div class="Form-check">
            <input class="form-check-input" id="HDFDeclaration" name="HDFDeclaration" type="checkbox" value="true" #iConfirmMedicalDisclosure (click)="acceptOrder()">
            <span class="Form-label-text">
              أقر بأني حصلت على كافة نماذج الإفصاحات الطبية المعتمدة بعد تعبأتها بالرجوع للعاملين في المنشأة بالشكل الصحيح كما تم إدخالها قبل الحصول على التسعيرات، وأخذ توقيعهم عليها وتوثيقها بختم المنشأة، وأتعهد بالاحتفاظ بها والتمكين من الوصول إليها عند الحاجة.
            </span>
        </div>
          <span class="field-validation-error" id="HDFDeclarationError"></span>
        </div>
  
        <div class="mt-4 payment-button payment-summary">
          <div class="d-block d-md-none mobile-price-box">
            <div class="row align-items-center justify-content-between mb-2">
              <div class="col-6 blue-text">
                المجموع
                <small class="text-dark">يشمل جميع الضرائب والرسوم</small>
              </div>
              <div class="col-6 blue-text text-end">
                22,986.20 ر.س
                <a href="https://health.tameeni.com/application/summary/MzQ1NjY5Mg%3D%3D?appQuoteID=MjY4ODExODg%3D#mobpolicyprice-amount" id="mobile-price">
                  <img src="/assets/images/masarat/order/images/blue-arrow-down.svg" width="16" alt="">
                </a>
              </div>
            </div>
          </div>
  
          <div class="actions clearfix">
            <ul class="d-flex align-items-center justify-content-between flex-column flex-lg-row px-3 px-lg-0 payment-summary-ul mb-5" id="bookmarks-ul" role="menu" aria-label="Pagination">
              
                <a routerLink="/2" class="btn btn-info " id="btnBacktoQuoteList" data-cmd="btnBacktoQuoteList">
                    <i class="mdi mdi-chevron-right align-middle"></i>
                  العودة إلى عروض الأسعار

                </a>
              
            </ul>
          </div>
        </div>
      </form>
  

    </div> <!--End col-->
        
        <div class="col col-lg-4 col-sm-12 fade-in">
          <div class="fixed-container sticky-bar">
            
            <div class="accordion-item fade-in fixed-container">
              <div class="accordion-header bg-light" id="headingInsCompDetails">
                  <button 
                      class="accordion-button collapsed  border-0 rounded"
                      [ngClass]="{'border-0': true, 'rounded': isCollapsed5}"
                      type="button"
                      data-bs-toggle="collapse" 
                      data-bs-target="#headingInsCompDetailsContent"
                      aria-expanded="true" 
                      style="cursor: pointer;"
                      (click)="toggleCollapse('isCollapsed5',  isCollapsed5)"
                      aria-controls="headingInsCompDetailsContent">
                      <div class="btn-content">
                        <div class="right-site-btn-content"> 
                        <span class="btn-title">
                          <i class="mdi mdi-invoice"></i>
                          تفاصيل الطلب                        
                        </span>
                        </div>
                        <div class="left-site-btn-content">
                          <i class="mdi mdi-chevron-down" *ngIf="!isCollapsed5"></i>
                          <i class="mdi mdi-chevron-up" *ngIf="isCollapsed5"></i>
                        </div>
                      </div>
                </button>
              </div>
          
              <div id="headingInsCompDetailsContent" class="accordion-collapse border-0 small" aria-labelledby="headingInsCompDetails" data-bs-parent="#accordionExample">
                
                <!--<div class="card rounded border-0  overflow-hidden">
                    <div class="row align-items-center g-0">
                        <div class="col col-lg-7 col-md-12 order-2 order-md-1">
                            <div class="card-body content">
                                <h5><a href="javascript:void(0)" class="card-title title text-dark">
                                    <span id="ICCompanyName" style="color:#026a7c">{{insCompanyName}}</span></a></h5>
                                <p ></p>
                                
                                <p class="mb-0" >
                                    <i class="uil uil-check-square text-primary me-1"></i>رقم التسعيرة : {{quotationId}}
                                </p>

                                <p class="mb-0" >
                                  <i class="uil uil-check-square text-primary me-1"></i>عدد المؤمن لهم : {{totalMemberCount}}
                                </p>
                                <p class="mb-2" >
                                  <i class="uil uil-check-square text-primary me-1"></i>الفئات مختارة : {{insClassCount}}
                                </p>
                                
                                <span class="mb-0 text-nowrap">
                                  <small>تأكد من البيانات و اتبع مراحل الدفع على اليمين</small>
                                </span>
                            </div>
                        </div>
                        
                        <div class="col col-lg-5 col-md-12 order-1 order-md-2 image-container">
                            <img src="/assets/images/masarat/quotation/ins-company-logos/{{quotationInsCompanyCode}}.png" alt="logo-insurance-company">
                          
                        </div>
              
                    
                        
                    </div> 
                </div>
            </div>
          </div>-->

          <main class="ticket-system">
            <div class="top">
            <div class="printer"></div>
            </div>
            <div class="receipts-wrapper">
               <div class="receipts">
                  <div class="receipt">
                    
                    <div class="card rounded border-0  overflow-hidden">
                      <div class="row align-items-center g-0">
                          
                        
                        
                        <div class="row">
                          <div class="col col-lg-12 col-md-12 order-1 order-md-2 text-center">
                            <span class="mb-0 text-nowrap">
                              <h5>
                                <a href="{{insCompanyNameWebsite}}">
                                  <span id="ICCompanyName" style="color:#026a7c">{{insCompanyName}}</span>
                                </a>
                              </h5>
                            </span>
                            <span class="mb-0 text-nowrap text-muted">
                              <b>وثائق الشركات الصغيرة و المتوسطة</b>
                            </span>
                          </div>
                        </div>
                        
                        <div class="col col-lg-7 col-md-12 order-2 order-md-1">
                            <div class="card-body content">
                                <!--<h5 class="text-nowrap"><a href="javascript:void(0)" class="card-title title text-dark">
                                    <span id="ICCompanyName" style="color:#026a7c">{{insCompanyName}}</span></a></h5>-->
                                <p ></p>
                                
                                <p class="mb-0" >
                                  <i class="uil uil-check-square text-primary me-1"></i>عدد المؤمن لهم : {{totalMemberCount}}
                                </p>
                                <p class="mb-2" >
                                  <i class="uil uil-check-square text-primary me-1"></i>الفئات مختارة : {{insClassCount}}
                                </p>
                                
                            </div>
                            <span class="mb-0 text-nowrap">
                              <small>تأكد من البيانات و اتبع مراحل الدفع على اليمين</small>
                            </span>
                        </div>
                        <div class="col col-lg-5 col-md-12 order-1 order-md-2">
                            <img src="/assets/images/masarat/quotation/ins-company-logos/{{quotationInsCompanyCode}}.png" class="img-fluid" alt="logo-insurance-company">
                            
                          </div>
                        
                        
                        
                          
                      </div> 
                  </div>
                    
                    <div class="card rounded border-top border-1  overflow-hidden">
                      <div class="row align-items-center g-0">
                          <div class="col col-lg-12 col-md-12 order-2 order-md-1">
                              <div class="card-body content">
                                <div class="row">
                                  <div class="col col-lg-12 col-md-12 order-1 order-md-2 text-center">
                                    <span class="mb-0 text-nowrap">
                                      <h5>
                                        <span id="ICCompanyName" style="color:#026a7c">ملخص الشراء</span>
                                      </h5>
                                    </span>
                                  </div>
                                </div>
                                  <div class="ticket-body">
                                    <div class="ticket-section">
                                        <span>
                                          المجموع (بدون ضريبة):
              
                                          </span>
                                        <span class="text-nowrap">
                                          {{quotationBeforeVat | number:'1.2-2'}} ر.س
                                        </span>
                                    </div>
                                    <div class="ticket-section">
                                        <span>
                                          +ضريبة القيمة المضافة (15.00%):
                                        </span>
                                        <span class="text-nowrap">
                                          {{quotationVatAmount | number:'1.2-2'}} ر.س
                                        </span>
                                    </div>
                    
                                    
                                  </div>
                                  <div class="ticket-body border-dashed border-top">
                                    <div class="ticket-section">
                                      <span class="h5">المبلغ الإجمالي</span>
                                      <span class="text-primary fw-bold h5 text-nowrap">{{quotationPrice | number:'1.2-2'}} ر.س</span>
                                      
                                    </div>
                                    <span class="text-muted mb-0 text-nowrap float-end">
                                      <small>شامل جميع الضرائب و الرسوم</small>                                  
                                    </span>
                                    
                                  </div>
                              </div> 
                          </div>
                      </div> 
                    </div>
                     
                  </div>
                  <div class="receipt qr-code">

                    <div class="row align-items-center">
                      <div class="col-3">
                        <svg class="qr" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.938 29.938">
                          <path d="M7.129 15.683h1.427v1.427h1.426v1.426H2.853V17.11h1.426v-2.853h2.853v1.426h-.003zm18.535 12.83h1.424v-1.426h-1.424v1.426zM8.555 15.683h1.426v-1.426H8.555v1.426zm19.957 12.83h1.427v-1.426h-1.427v1.426zm-17.104 1.425h2.85v-1.426h-2.85v1.426zm12.829 0v-1.426H22.81v1.426h1.427zm-5.702 0h1.426v-2.852h-1.426v2.852zM7.129 11.406v1.426h4.277v-1.426H7.129zm-1.424 1.425v-1.426H2.852v2.852h1.426v-1.426h1.427zm4.276-2.852H.002V.001h9.979v9.978zM8.555 1.427H1.426v7.127h7.129V1.427zm-5.703 25.66h4.276V22.81H2.852v4.277zm14.256-1.427v1.427h1.428V25.66h-1.428zM7.129 2.853H2.853v4.275h4.276V2.853zM29.938.001V9.98h-9.979V.001h9.979zm-1.426 1.426h-7.127v7.127h7.127V1.427zM0 19.957h9.98v9.979H0v-9.979zm1.427 8.556h7.129v-7.129H1.427v7.129zm0-17.107H0v7.129h1.427v-7.129zm18.532 7.127v1.424h1.426v-1.424h-1.426zm-4.277 5.703V22.81h-1.425v1.427h-2.85v2.853h2.85v1.426h1.425v-2.853h1.427v-1.426h-1.427v-.001zM11.408 5.704h2.85V4.276h-2.85v1.428zm11.403 11.405h2.854v1.426h1.425v-4.276h-1.425v-2.853h-1.428v4.277h-4.274v1.427h1.426v1.426h1.426V17.11h-.004zm1.426 4.275H22.81v-1.427h-1.426v2.853h-4.276v1.427h2.854v2.853h1.426v1.426h1.426v-2.853h5.701v-1.426h-4.276v-2.853h-.002zm0 0h1.428v-2.851h-1.428v2.851zm-11.405 0v-1.427h1.424v-1.424h1.425v-1.426h1.427v-2.853h4.276v-2.853h-1.426v1.426h-1.426V7.125h-1.426V4.272h1.426V0h-1.426v2.852H15.68V0h-4.276v2.852h1.426V1.426h1.424v2.85h1.426v4.277h1.426v1.426H15.68v2.852h-1.426V9.979H12.83V8.554h-1.426v2.852h1.426v1.426h-1.426v4.278h1.426v-2.853h1.424v2.853H12.83v1.426h-1.426v4.274h2.85v-1.426h-1.422zm15.68 1.426v-1.426h-2.85v1.426h2.85zM27.086 2.853h-4.275v4.275h4.275V2.853zM15.682 21.384h2.854v-1.427h-1.428v-1.424h-1.427v2.851zm2.853-2.851v-1.426h-1.428v1.426h1.428zm8.551-5.702h2.853v-1.426h-2.853v1.426zm1.426 11.405h1.427V22.81h-1.427v1.426zm0-8.553h1.427v-1.426h-1.427v1.426zm-12.83-7.129h-1.425V9.98h1.425V8.554z"></path>
                       </svg>
                      </div>
                      <div class="col-7">
                        <div class="description ps-3 text-nowrap">
                          <h3>رقم التسعيرة : {{quotationId}}</h3>
                          <p class="mb-0" >
                               
                          </p>
                       </div>
                      </div>
                  </div>

                 </div>
               </div>
            </div>
         </main>
        </div>
      </div>

          <br>
          
          <!--<div class="accordion-item fade-in fixed-container">
            <div class="accordion-header bg-light" id="headingOrderSummary">
                <button 
                    class="accordion-button collapsed  border-0 rounded"
                    [ngClass]="{'border-0': true, 'rounded': isCollapsed6}"
                    type="button"
                    data-bs-toggle="collapse" 
                    data-bs-target="#headingOrderSummaryContent"
                    aria-expanded="true" 
                    style="cursor: pointer;"
                    (click)="toggleCollapse('isCollapsed6',  isCollapsed6)"
                    aria-controls="headingOrderSummaryContent">
                    <div class="btn-content">
                      <div class="right-site-btn-content"> 
                      <span class="btn-title">
                        ملخص عملية الشراء
                      </span>
                      </div>
                      <div class="left-site-btn-content">
                        <i class="mdi mdi-chevron-down" *ngIf="!isCollapsed6"></i>
                        <i class="mdi mdi-chevron-up" *ngIf="isCollapsed6"></i>
                      </div>
                    </div>
              </button>
            </div>
            
            <div id="headingOrderSummaryContent" class="accordion-collapse border-0 small" aria-labelledby="headingOrderSummary" data-bs-parent="#accordionExample">
                <div class="card rounded border-0  overflow-hidden">
                    <div class="row align-items-center g-0">
                        <div class="col col-lg-12 col-md-12 order-2 order-md-1">
                            <div class="card-body content">
                                <div class="ticket-body">
                                  <div class="ticket-section">
                                      <span>
                                        المجموع (بدون ضريبة):
            
                                        </span>
                                      <span>
                                        {{quotationBeforeVat}} ر.س
                                      </span>
                                  </div>
                                  <div class="ticket-section">
                                      <span>
                                        .+ضريبة القيمة المضافة (15.00%):
                                      </span>
                                      <span>
                                        {{quotationVatAmount}} ر.س
                                      </span>
                                  </div>
                  
                                  
                                </div>
                                <div class="ticket-body border-top">
                                  <div class="ticket-section">
                                    <span>المبلغ الإجمالي</span>
                                    <span class="text-primary fw-bold h5">1,032.70 ريس</span>
                                    
                                  </div>
                                  <span class="text-muted mb-0 text-nowrap float-end">
                                    <small>شامل جميع الضرائب و الرسوم</small>                                  
                                  </span>
                                  
                                </div>
                                
                            </div>
                            
                            
                        </div>
                        
                         
                    </div> 
                </div>
            </div>
          </div>-->

          </div>
        </div>

    </div> <!--END ROW-->
     
  <span style="color:red" id="MsgPaymentMethodRequired"></span>
 </div>
</section>

  